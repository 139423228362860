export enum Color {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  quaternary = "quaternary",
  quinary = "quinary",
  sixth = "sixth",
  white = "white",
  black = "black",
  danger = "danger",
  seventh = "seventh",
  eighth = "eighth",
  radialGradient = "radialGradient",
  linearGradient = "linearGradient",
  linearGradient1 = "linearGradient1",
  linearGradient2 = "linearGradient2"
}

export enum Size {
  sm = "sm",
  md = "md",
  lg = "lg"
}
