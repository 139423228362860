import React, { useState, useEffect } from "react";

interface ImageGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  currentIndex: number;
  onPrev: () => void;
  onNext: () => void;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  isOpen,
  onClose,
  images,
  currentIndex,
  onPrev,
  onNext
}) => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      // Load current image and adjacent images
      const imagesToLoad = [
        images[currentIndex],
        images[(currentIndex + 1) % images.length],
        images[(currentIndex - 1 + images.length) % images.length]
      ];

      setLoadedImages((prev) =>
        Array.from(new Set([...prev, ...imagesToLoad]))
      );
    }
  }, [isOpen, currentIndex, images]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      } else if (event.key === "ArrowLeft") {
        onPrev();
      } else if (event.key === "ArrowRight") {
        onNext();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose, onPrev, onNext]);

  if (!isOpen) return null;

  const buttonStyle = {
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "44px",
    cursor: "pointer",
    transition: "background-color 0.3s ease"
  };

  return (
    <div
      className="image-gallery-modal-overlay"
      role="dialog"
      aria-modal="true"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999
      }}
    >
      <div
        className="image-gallery-modal-content"
        style={{ position: "relative" }}
      >
        <button
          className="close-button"
          onClick={onClose}
          onKeyDown={(e) => {
            if (e.key === "Escape") onClose();
          }}
          type="button"
          aria-label="Close modal"
          style={{
            ...buttonStyle,
            position: "absolute",
            top: "10px",
            right: "10px"
          }}
        >
          &times;
        </button>
        {loadedImages.includes(images[currentIndex]) ? (
          <img
            src={images[currentIndex]}
            alt={`${currentIndex + 1} of ${images.length}`}
            className="gallery-image"
            style={{
              maxHeight: "90vh",
              maxWidth: "90vw",
              objectFit: "contain"
            }}
          />
        ) : (
          <div className="image-placeholder">Loading...</div>
        )}
        <button
          className="nav-button prev"
          onClick={onPrev}
          type="button"
          aria-label="Previous image"
          style={{
            ...buttonStyle,
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)"
          }}
        >
          &lt;
        </button>
        <button
          className="nav-button next"
          onClick={onNext}
          type="button"
          aria-label="Next image"
          style={{
            ...buttonStyle,
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)"
          }}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ImageGalleryModal;
