import { useEffect, useState } from "react";

export enum SameSite {
  Lax = "Lax",
  None = "None",
  Strict = "Strict"
}

const useCookies = (sameSite = SameSite.Lax) => {
  const getAllCookies = () =>
    document.cookie.split("; ").map((cookie) => cookie.split("="));

  const getCookie = (str: string) => {
    const newCookies = document.cookie
      .split("; ")
      .map((cookie) => cookie.split("="));
    return newCookies.find((cookie) => cookie[0].includes(str))?.[1];
  };

  const setCookie = (
    key: string | [string, string][],
    val = "",
    path = "/"
  ) => {
    const newCookies = document.cookie
      .split("; ")
      .map((cookie) => cookie.split("="));
    if (newCookies[0][0] === "") {
      newCookies.pop();
    }
    if (Array.isArray(key)) {
      key.forEach((newVal) => {
        newCookies.push(newVal);
      });
    } else {
      const idx = newCookies.findIndex((cookie) => cookie[0].includes(key));
      if (idx !== -1) {
        newCookies[idx][1] = val;
      } else {
        newCookies.push([key, val]);
      }
    }

    newCookies.forEach((cookie) => {
      document.cookie = `${cookie.join(
        "="
      )}; path=${path}; SameSite=${sameSite}`;
    });
  };

  return {
    getAllCookies,
    getCookie,
    setCookie
  };
};

export default useCookies;
