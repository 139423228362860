import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Text from "./Text";

interface BannerProps {
  title: string;
  description: string;
  ctaText: string;
  ctaAction: (email: string) => void;
  style?: React.CSSProperties;
}

const BannerWrapper = styled.div`
  background-color: var(--lightPurple);
  border: 1px solid var(--tertiary);
  border-radius: 0.25rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--tertiary);
  margin-bottom: 0.5rem;
`;

const Description = styled(Text)`
  font-size: 1rem;
  color: var(--quaternary);
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  background-color: var(--tertiary);
  color: var(--white);
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--secondary);
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
  max-width: 400px;
`;

const EmailInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--quaternary);
  border-radius: 0.25rem;
  font-size: 1rem;
`;

const Banner: React.FC<BannerProps> = ({
  title,
  description,
  ctaText,
  ctaAction,
  style
}) => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    ctaAction(email);
    setEmail("");
  };

  return (
    <BannerWrapper style={style}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <InputWrapper>
        <EmailInput
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <StyledButton onClick={handleSubmit}>{ctaText}</StyledButton>
      </InputWrapper>
    </BannerWrapper>
  );
};

export default Banner;
