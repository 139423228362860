import React, { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Text from "../../components/Text";
import SplitView from "../../components/SplitView";
import Field from "../../components/Field";
import { OptionProp } from "../../components/Select";

import Button from "../../components/Button";
import { Color } from "../../types";
import Flex from "../../components/Flex";
import Modal from "../../components/Modal";
import { AppContext } from "../../components/AppContext";
import LinkButton from "../../components/LinkButton";

const defaultForm = {
  name: "",
  password: "",
  confirmpassword: "",
  email: "",
  // title: "",
  // market: "",
  // other: "",
  consent: "true",
  verificationcode: ""
};

const SignUpModal = ({
  header,
  isOpen,
  toggle,
  preHeader,
  underImage,
  postUnderImage
}: any) => {
  const history = useHistory();
  const { setUser, setOpenLoginModal, mobileSize } = useContext(AppContext);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isConfirmSignUpLoading, setIsConfirmSignUpLoading] = useState(false);
  const [signUpError, setSignUpError] = useState<string | undefined>();
  const [withEmail, setWithEmail] = useState(false);
  const [confirmSignup, setConfirmSignup] = useState(false);
  const [signup, setSignup] = useState(false);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [errors, setErrors] = useState<any>({
    name: false,
    email: false
    // title: false,
    // market: false,
    // other: false,
    // consent: false
  });
  const [form, setForm] = useState(defaultForm);
  const {
    name,
    password,
    confirmpassword,
    email,
    // title,
    // market,
    // other,
    consent,
    verificationcode
  } = form;

  useEffect(() => {
    async function signUp() {
      try {
        const { user } = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name,
            "custom:title": "Placeholder",
            "custom:market": "Placeholder",
            "custom:other": "Placeholder",
            "custom:consent": "true"
          }
        });
        if (user) {
          console.log("user", user);
          setUser(user);
          setSignup(false);
          setWithEmail(false);
          setConfirmSignup(true);
        }
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log("error signing up:", error);
        if (error.code === "UsernameExistsException") {
          setSignUpError("Error. The user already exists.");
        } else {
          setSignUpError(error.message);
        }
      }
      setIsSignUpLoading(false);
    }
    if (signup) {
      setIsSignUpLoading(true);
      signUp();
    }
  }, [signup, form]);

  useEffect(() => {
    async function confirmSignUp() {
      try {
        await Auth.confirmSignUp(email, verificationcode);
        setSubmitConfirmation(false);
        setConfirmSignup(false);
        setThankYou(true);
        // Step 2: Auto sign-in
        const userSignIn = await Auth.signIn(email, password);
        console.log("Auto sign-in success!", userSignIn);
        setUser(userSignIn);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error signing up:", error);
      }
      setIsConfirmSignUpLoading(false);
    }
    if (submitConfirmation) {
      setIsConfirmSignUpLoading(true);
      confirmSignUp();
    }
  }, [submitConfirmation, form]);

  const allowAddingPassword = useCallback(() => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );

    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }

    return isEveryElementFilled;
  }, [form, errors]);

  const federatedSignIn = useCallback(async () => {
    // if (allowAddingPassword()) {
    try {
      localStorage.setItem("prevLoc", history.location.pathname);
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: JSON.stringify({
          email,
          name,
          // "custom:title": title,
          // "custom:market": market,
          // "custom:other": other,
          "custom:consent": "true"
        })
      } as any);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      console.log("Authenticated user", authenticatedUser);
      setUser(authenticatedUser);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error signing up:", error);
    }
    // }
  }, [email, name, consent, allowAddingPassword]);

  const handleToggle = () => {
    if (!confirmSignup) {
      setWithEmail(false);
      setThankYou(false);
      setForm(defaultForm);
      toggle();
    }
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(email);
    } catch (err) {
      // eslint-disable-next-line
      console.log("error resending code: ", err);
    }
  };

  const allowSignUp =
    (password === "" && confirmpassword === "") || password !== confirmpassword;

  const modalPadding = mobileSize ? "2rem" : "2rem";

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      style={{
        padding: withEmail || confirmSignup ? modalPadding : undefined,
        width: withEmail || confirmSignup ? "max-content" : undefined,
        minWidth: mobileSize ? "300px" : "400px"
      }}
    >
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/sign-up" />
        <title>Chalet | Sign Up For a Free Account</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content={
            "Sign Up For A Free Account and Get STR(Airbnb) Market Report. " +
            "Also get unlimited predictions with our Airbnb Investment Calculator"
          }
        />
        <meta
          property="og:title"
          content="Chalet | Short-Term (Airbnb) Rental Regulation"
        />
        <meta
          property="og:description"
          content={
            "Sign Up For A Free Account and Get STR(Airbnb) Market Report. " +
            "Also get unlimited predictions with our Airbnb Investment Calculator"
          }
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.getchalet.com/sign-up" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      {!withEmail && !confirmSignup && !thankYou && (
        <>
          <div
            style={{
              alignItems: "center",
              display: mobileSize ? "block" : "flex",
              flexDirection: "column",
              padding: "1rem"
            }}
          >
            {preHeader && (
              <Text
                color={Color.quinary}
                weight={400}
                style={{
                  fontSize: "1.5rem"
                }}
              >
                {preHeader}
              </Text>
            )}
            <Text
              color={Color.tertiary}
              weight={400}
              style={{
                fontSize: "1.5rem",
                textAlign: "center",
                display: "block"
              }}
            >
              {header}
            </Text>
          </div>
          <SplitView
            split="1:1"
            style={{
              maxHeight: "calc(100vh - 6rem - 100px)",
              overflow: "auto",
              overflowX: mobileSize ? "hidden" : undefined
            }}
          >
            <div style={{ width: "100%" }}>
              <Field
                name="name"
                placeholder="Name"
                type="text"
                value={name}
                invalid={errors.name}
                onChange={onChange}
              />
              <Field
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                invalid={errors.email}
                onChange={onChange}
              />
              <Field
                name="password"
                placeholder="Create Password"
                type="password"
                value={password}
                onChange={onChange}
              />
              <Field
                name="confirmpassword"
                placeholder="Confirm Password"
                type="password"
                value={confirmpassword}
                onChange={onChange}
                invalid={
                  password !== "" && confirmpassword !== "" && allowSignUp
                }
                valid={!allowSignUp}
              />
              {/* <Select
                name="title"
                options={titleOptions}
                placeholder="You are ..."
                value={titleOptions.find((option) => option.label === title)}
                invalid={errors.title}
                onChange={onChange}
              /> */}
              {/* <Select
                name="market"
                options={marketOptions}
                placeholder="Market"
                value={marketOptions.find((option) => option.label === market)}
                invalid={errors.market}
                onChange={onChange}
              /> */}
              {/* <TextArea
                name="other"
                placeholder="If “Other” market is selected, please type the name of the market here. We are actively
                building new reports and will notify you when they are available..."
                style={{ minHeight: "80px", fontSize: "1rem", fontWeight: 300 }}
                value={other}
                onChange={onChange}
                tabIndex={0}
              />
              <CheckboxRadio
                name="consent"
                label={<ConsentLabel />}
                style={{ fontSize: "0.75rem" }}
                value={consent}
                invalid={errors.consent}
                checked={consent}
                onChange={onChange}
              /> */}
              {Object.values(errors).some((value) => value) && (
                <Text
                  color={Color.danger}
                  style={{
                    display: "block",
                    padding: "0.5rem",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  Fields{" "}
                  {Object.entries(errors)
                    .map(([key, value]) => (value ? key : undefined))
                    .filter((str) => str !== undefined)
                    .join(", ")}{" "}
                  are missing values
                </Text>
              )}
              {/* <Button
                style={{ marginBottom: "1rem", width: "100%" }}
                onClick={() => allowAddingPassword() && setWithEmail(true)}
                data-id="modal_signup_email_button"
              >
                Sign up with email
              </Button> */}
              <Button
                isLoading={isSignUpLoading}
                disabled={allowSignUp}
                style={{ margin: "1rem 0 0.5rem", width: "100%" }}
                onClick={() => {
                  setSignup(true);
                }}
              >
                Sign up with email
              </Button>
              <Button
                color={Color.primary}
                style={{ width: "100%" }}
                onClick={federatedSignIn}
              >
                <img
                  src="./images/google_logo_1.png"
                  alt="google"
                  style={{ marginRight: "0.5rem" }}
                />
                Sign up with Google
              </Button>
              <Text
                color={Color.tertiary}
                weight={300}
                style={{
                  fontSize: "1rem",
                  textAlign: "center",
                  display: "block"
                }}
              >
                {"Already a member? "}
                <LinkButton
                  style={{ fontSize: "1rem" }}
                  onClick={() => {
                    toggle(true);
                    setOpenLoginModal(true);
                  }}
                >
                  Login
                </LinkButton>
              </Text>
            </div>
            <Flex
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                width: mobileSize ? "calc(100% - 2rem)" : "100%"
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/mark_report.webp`}
                alt="Market Report"
                style={{
                  padding: !mobileSize ? "0rem" : "initial",
                  width: "100%"
                }}
              />
              {underImage && (
                <Text
                  color={Color.tertiary}
                  weight={300}
                  style={{ fontSize: "1.5rem" }}
                >
                  {underImage}
                </Text>
              )}
              {postUnderImage && (
                <Text
                  color={Color.quaternary}
                  weight={300}
                  style={{ fontSize: "1rem" }}
                >
                  {postUnderImage}
                </Text>
              )}
            </Flex>
          </SplitView>
        </>
      )}
      {withEmail && (
        <>
          <Text
            weight={400}
            style={{
              fontSize: mobileSize ? "1rem" : "1.5rem",
              padding: "0rem",
              textAlign: "center",
              display: "block",
              marginBottom: "1.5rem",
              color: "var(--tertiary)"
            }}
          >
            CREATE PASSWORD
          </Text>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={onChange}
          />

          <Button
            color={Color.quinary}
            outline
            onClick={() => setWithEmail(false)}
            style={{ width: "100%" }}
          >
            Back
          </Button>
          {signUpError && (
            <Text
              color={Color.secondary}
              style={{ display: "block", paddingTop: "1rem" }}
            >
              {signUpError}
            </Text>
          )}
        </>
      )}
      {confirmSignup && (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <Text>Check your email </Text>
            <Text weight={600}>{` ${email} `}</Text>
            <Text>for a verification code and provide it below</Text>
          </div>
          <Field
            name="verificationcode"
            placeholder="Verification Code"
            value={verificationcode}
            onChange={onChange}
          />
          <Button
            isLoading={isConfirmSignUpLoading}
            disabled={allowSignUp}
            style={{ margin: "1rem 0 0.5rem", width: "100%" }}
            onClick={() => {
              setSubmitConfirmation(true);
            }}
          >
            Confirm Sign up
          </Button>
          <Flex style={{ justifyContent: "center" }}>
            <LinkButton onClick={resendCode} style={{ fontSize: "1rem" }}>
              Resend code?
            </LinkButton>
          </Flex>
        </>
      )}
      {thankYou && (
        <Flex
          style={{
            flexDirection: "column",
            padding: mobileSize ? "0.5rem" : "3rem",
            textAlign: "center"
          }}
        >
          <Text
            weight={400}
            color={Color.quaternary}
            style={{ fontSize: "1.5rem" }}
          >
            Thank you for joining us!
          </Text>
          <Text style={{ fontSize: "1.5rem" }}>
            <Text color={Color.tertiary} style={{ fontSize: "1.5rem" }}>
              The Market Report
            </Text>{" "}
            will be sent to your {email}
          </Text>
        </Flex>
      )}
    </Modal>
  );
};

SignUpModal.defaultProps = {
  header: "SIGN UP FOR A FREE ACCOUNT AND GET STR (AIRBNB) MARKET REPORT",
  postUnderImage: "new reports released quarterly"
};

export default SignUpModal;
