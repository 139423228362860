import styled, { css } from "styled-components";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { Auth } from "aws-amplify";
import Button, { ButtonType, StyledButton } from "../Button";
import NavbarItem, { StyledNavbarItem } from "./NavbarItem";
import Flex from "../Flex";
import hexToRgb from "../hexToRgb";
import { Colors } from "../GlobalStyle";
import NavbarMenu, { NavbarMenuList } from "./NavbarMenu";
import ContactUsModal from "../../pages/modals/ContactUsModal";
import { AppContext } from "../AppContext";
import NavbarMobileMenu from "./NavbarMobileMenu";
import { Color } from "../../types";
import Image from "../Image";
import Popover, { Placement } from "../Popover";
import NavbarMobileSubmenu from "./NavbarMobileSubmenu";
import "../../styles/Navbar.scss";

const LinkWrapper = styled(Link)`
  display: contents;
`;

const StyledNavbar = styled.nav<{
  $atTop: boolean;
  $mobileSize: boolean;
  $visible: boolean;
}>`
  align-items: center;
  // background-color: ${(props) =>
    props.$atTop ? "transparent" : `rgba(${hexToRgb(Colors.white)}, 0.5)`};
  background-image: url("/images/about2.webp");
  background-position: top;
  background-size: calc(var(--min-height) * 2);
  display: flex;
  justify-content: space-between;
  // padding: 0 7.5vw;
  margin: 0 auto;
  position: fixed;
  // top: ${(props) => (props.$visible ? "0" : "-100")}%;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1060;
  .phoneNumber {
    text-decoration: none;
    color: var(--quinary);
    padding: 0.75rem 1rem;
    border-color: var(--quinary);
    border-radius: 0.25rem;
    // color: var(--white);
    display: block;
    margin: 0 auto;
    font-size: 1rem;
    height: 100px;
    text-align: center;
    @media(max-width: 800px) {
      margin-top: 1rem;
    }
    }
  }
  ${(props) =>
    props.$mobileSize &&
    css`
      max-height: 80px;
      height: 100%;
      padding: 0.5rem 1.5rem;
      a {
        height: 100%;
      }
      svg {
        padding: 0.5rem 0;
      }
    `};
`;

const NavbarList = styled.ul`
  align-items: center;
  // border-right: 1px solid var(--quinary);
  display: flex;
  height: 50px;
  list-style: none;
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:last-of-type {
    border-right: none;
  }
  ${StyledButton},
  ${StyledNavbarItem} {
    &:hover {
      background: var(--quinary);
      color: var(--white);
      opacity: 1;
      // border-bottom: 2px solid white;
    }
  }
`;

const LogoImg = styled(Image)`
  width: 90px;
  margin-bottom: -0.5rem;

  @media (max-width: 720px) {
    margin-top: 0.5rem;
    margin-bottom: 0;
    width: inherit;
  }
`;

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { mobileSize, setOpenLoginModal, user } = useContext(AppContext);
  const scrollTopRef = React.useRef(0);

  const buttonRef = useRef<HTMLButtonElement>();
  const popoverRef = useRef<HTMLDivElement>();
  const [atTop, setAtTop] = useState(true);
  const [visible, setVisible] = useState(true);
  const [isUserOpen, setIsUserOpen] = useState(false);

  const handleMouseOver = (e: any) => {
    // console.log("Button ref", buttonRef);
    e.persist();
    if (e.type === "mouseenter") {
      setIsUserOpen(true);
    } else if (
      e.type === "mouseleave" &&
      !buttonRef.current?.contains(e.relatedTarget) &&
      !popoverRef.current?.contains(e.relatedTarget)
    ) {
      setIsUserOpen(false);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // eslint-disable-next-line
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY;
      setVisible(st < scrollTopRef.current || st < 133);
      setAtTop(st < 133);
      scrollTopRef.current = st;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/login") {
      setOpenLoginModal(true);
    }
    // if (location.pathname === "/contact-us") {
    //   // handleContactUsClick();
    // }
  }, [location]);

  return (
    <>
      <StyledNavbar
        id="main-navigation"
        $atTop={atTop}
        $visible={visible}
        $mobileSize={mobileSize}
      >
        <Link to="/">
          <LogoImg
            // src={`${process.env.PUBLIC_URL}/logo.webp`}
            loading="eager"
            src={`${process.env.PUBLIC_URL}/images/logo2024.png`}
            alt="Chalet"
            height="48px"
            width="90px"
          />
        </Link>
        {mobileSize && (
          <NavbarMobileMenu>
            {/* <NavbarMobileMenu.Button
              href="/str-calculator"
              data-id="header_airbnb_calculator_button"
            >
              Airbnb Calculator
            </NavbarMobileMenu.Button> */}
            {/* <NavbarMobileMenu.Button
              href="/chalet-concierge"
              data-id="header_how_it_works_button"
            >
              How It Works
            </NavbarMobileMenu.Button> */}
            {/* {!realtorPages && (
              <Button
                type={ButtonType.link}
                data-id="header-contact-us_button"
                style={{
                  backgroundColor: "var(--seventh)",
                  color: "var(--tertiary)",
                  textTransform: "uppercase",
                  margin: "0 auto",
                  display: "block",
                  fontWeight: "400"
                }}
                onClick={handleInvestorGuides}
                onMouseEnter={handleInvestorGuides}
              >
                Investor Guides
              </Button>
            )} */}
            {/* <Button
              type={ButtonType.link}
              data-id="header-best-airbnbs"
              style={{
                // backgroundColor: "var(--seventh)",
                color: "var(--primary)",
                fontSize: "1rem",
                fontWeight: "400",
                textAlign: "center",
                margin: "0 auto",
                textTransform: "uppercase",
                textDecoration: "underline"
              }}
              onClick={handleCostSeg}
            >
              Cost Segregation
            </Button> */}
            {/* <Button
              type={ButtonType.link}
              data-id="header-best-airbnbs"
              style={{
                // backgroundColor: "var(--seventh)",
                color: "var(--primary)",
                fontSize: "1rem",
                fontWeight: "400",
                textAlign: "center",
                margin: "0 auto",
                textTransform: "uppercase",
                textDecoration: "underline"
              }}
              onClick={handleResources}
            >
              STR Resources
            </Button> */}
            <LinkWrapper to="/analytics/overview">
              <Button
                type={ButtonType.link}
                data-id="header-best-airbnbs"
                style={{
                  // backgroundImage: "var(--quinary)",
                  // backgroundClip: "text",
                  margin: "0 auto",
                  color: "var(--quinary)",
                  // fontFamily: "Pacifico",
                  fontSize: "1.3rem",
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  textDecorationColor: "var(--tertiary)", // Add this line
                  textUnderlineOffset: "0.2em" // Add this line to adjust underline position
                  // fontWeight: "600"
                }}
              >
                Chalet Intel
              </Button>
            </LinkWrapper>
            {/* <NavbarMobileSubmenu
              title="Analyze"
              data-id="header_analyze_button"
            >
              <NavbarMobileSubmenu.Button
                style={{ textDecoration: "underline", fontWeight: 400 }}
                href="/analyze"
                data-id="navbar_analyze_button"
              >
                Analyze
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/best-airbnbs"
                data-id="header_investor_guides_button"
              >
                Top Airbnbs
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/str-calculator"
                data-id="header_airbnb_calculator_button"
              >
                Airbnb Calculator
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/rental-regulations"
                data-id="header_airbnb_regulation_button"
              >
                Short-Term Rental Regulation
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/blog"
                data-id="header_blog_button"
              >
                Blog
              </NavbarMobileSubmenu.Button>
            </NavbarMobileSubmenu> */}
            <NavbarMobileSubmenu title="Buy" data-id="header_buy_button">
              <NavbarMobileSubmenu.Button
                style={{ textDecoration: "underline", fontWeight: 400 }}
                href="/buy"
                data-id="navbar_buy_button"
              >
                Buy
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/real-estate-agents"
                data-id="header_find-real-estate-agents_button"
              >
                Find A Real Estate Agent
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/1031-exchange"
                data-id="header_1031-exchange_button"
              >
                1031 Exchange Investment Properties
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/chalet-concierge"
                data-id="header_how_it_works_button"
              >
                Airbnb Investment Consulting
              </NavbarMobileSubmenu.Button>
            </NavbarMobileSubmenu>
            <NavbarMobileSubmenu title="Manage" data-id="header_manage_button">
              <NavbarMobileSubmenu.Button
                href="/property-management"
                data-id="header_find-property-manager_button"
              >
                Find A Property Manager
              </NavbarMobileSubmenu.Button>
              <NavbarMobileSubmenu.Button
                href="/turnover-services"
                data-id="header_turnover-services_button"
              >
                Find A Turnover Service/Airbnb Cleaners
              </NavbarMobileSubmenu.Button>
              {/* <NavbarMobileSubmenu.Button
                href="/remote-manage-your-airbnbs"
                data-id="header_airbnb_consulting_button"
              >
                Chalet Remote Host
              </NavbarMobileSubmenu.Button> */}
            </NavbarMobileSubmenu>
            <Button
              onClick={() => history.push("/sell-your-str-social")}
              style={{
                margin: "0 auto",
                background: "none",
                color: "var(--tertiary)",
                textTransform: "uppercase",
                fontSize: "1rem",
                // textDecoration: "underline",
                display: "block",
                cursor: "pointer",
                fontWeight: "400"
              }}
            >
              Sell
            </Button>
            <Button
              onClick={() => history.push("/blog")}
              style={{
                margin: "0 auto",
                background: "none",
                color: "var(--tertiary)",
                textTransform: "uppercase",
                fontSize: "1rem",
                // textDecoration: "underline",
                display: "block",
                cursor: "pointer",
                fontWeight: "400"
              }}
            >
              Blog
            </Button>
            <PopupButton
              // type={ButtonType.link}
              id="DU51pTsh"
              hidden={{
                market_of_interest: "Not Specified",
                source_page: "mobile_navbar"
              }}
              data-id="header-contact-us_button"
              style={{
                background: "none",
                color: "var(--quinary)",
                textTransform: "uppercase",
                margin: "0 auto",
                padding: "0.7rem",
                border: 0,
                borderRadius: "0.25rem",
                boxShadow: "none",
                fontSize: "1rem",
                textDecoration: "underline",
                display: "block",
                cursor: "pointer",
                fontWeight: "400"
              }}
              // onClick={handleContactUsClick}
              // onMouseEnter={handleContactUsClick}
            >
              Connect With Airbnb Realtor
            </PopupButton>
            {!user && (
              <>
                <Button
                  type={ButtonType.link}
                  style={{
                    // background: "var(--tertiary)",
                    color: "var(--tertiary)",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    margin: "0 auto",
                    maxWidth: "200px"
                  }}
                  onClick={() => setOpenLoginModal(true)}
                >
                  Login
                </Button>
              </>
            )}
            {user && (
              <>
                <Button
                  type={ButtonType.link}
                  ref={buttonRef}
                  id="user-button"
                  onMouseEnter={handleMouseOver}
                  onMouseLeave={handleMouseOver}
                  style={{ textTransform: "uppercase" }}
                >
                  {(user as any)?.attributes ? (
                    <>
                      {(user as any)?.attributes.name?.split(" ")[0]}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="var(--quinary)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-down"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
                <Popover
                  ref={popoverRef}
                  color={Color.quinary}
                  isOpen={isUserOpen}
                  placement={Placement.bottomStart}
                  target="user-button"
                  onMouseLeave={handleMouseOver}
                >
                  <NavbarMenuList>
                    <Button
                      type={ButtonType.link}
                      onClick={signOut}
                      data-id="header_logout_button"
                    >
                      Logout
                    </Button>
                  </NavbarMenuList>
                </Popover>
                {/* {(user as any)?.attributes && (
                  <img
                    src="./images/ProfileIcon.png"
                    alt=""
                    style={{ height: "48px" }}
                  />
                )} */}
              </>
            )}
          </NavbarMobileMenu>
        )}
        {!mobileSize && (
          <Flex>
            <NavbarList>
              {/* <NavbarList>
                {/* <>
                  {!realtorPages && (
                    <Button
                      type={ButtonType.link}
                      data-id="header-best-airbnbs"
                      style={{
                        // backgroundColor: "var(--seventh)",
                        color: "var(--tertiary)",
                        fontSize: "1rem",
                        fontWeight: "400"
                      }}
                      onClick={handleInvestorGuides}
                    >
                      Investor Guides
                    </Button>
                  )}
                </> */}
              {/* </NavbarList> */}
              <LinkWrapper to="/analytics/overview">
                <Button
                  type={ButtonType.link}
                  data-id="header-chalet-intel"
                  style={{
                    // border: "4px double var(--white)",
                    // backgroundImage: "var(--quinary)",
                    // backgroundImage: "var(--radialGradient)",
                    // color: "transparent",
                    backgroundClip: "text",
                    color: "var(--quinary)",
                    // textDecoration: "underline solid var(--tertiary) 2px",
                    fontSize: "1rem",

                    // fontFamily: "Pacifico",

                    fontWeight: "500",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                    textDecorationColor: "var(--tertiary)", // Add this line
                    textUnderlineOffset: "0.2em" // Add this line to adjust underline position
                  }}
                >
                  Chalet Intel
                  {/* <Text
                  style={{
                    background: "var(--lightPurple)",
                    padding: "0.25rem 0.5rem",
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "0.25rem"
                  }}
                >
                  Beta
                </Text> */}
                </Button>
              </LinkWrapper>
              <Button
                type={ButtonType.link}
                data-id="header-best-airbnbs"
                style={{
                  // background: "var(--seventh)",
                  // color: "var(--tertiary)",
                  fontSize: "1rem",
                  fontWeight: "400",
                  textAlign: "center",
                  margin: "0 auto",
                  textTransform: "uppercase",
                  textDecoration: "underline"
                }}
                onClick={() => history.push("/airbnbs-for-sale")}
              >
                Airbnbs For Sale
              </Button>

              {/* <NavbarMenu
                href="/analyze"
                title="Analyze"
                data-id="header_analyze_button"
              >
                <NavbarItem
                  href="/best-airbnbs"
                  data-id="header_best-airbnbs_guides_button"
                >
                  Top Airbnbs
                </NavbarItem>
                <NavbarItem
                  href="/str-calculator"
                  data-id="header_airbnb_calculator_button"
                >
                  Airbnb Calculator
                </NavbarItem>
                <NavbarItem
                  href="/rental-regulations"
                  data-id="header_airbnb_regulation_button"
                >
                  Short-Term Rental Regulation
                </NavbarItem>
                <NavbarItem href="/blog" data-id="header_blog_button">
                  Blog
                </NavbarItem> */}
              {/* </NavbarMenu> */}
              <NavbarMenu href="/buy" title="Buy" data-id="header_buy_button">
                <NavbarItem
                  href="/real-estate-agents"
                  data-id="header_find-real-estate-agents_button"
                >
                  Find A Real Estate Agent
                </NavbarItem>
                <NavbarItem
                  href="/1031-exchange"
                  data-id="header_1031-exchange_button"
                >
                  1031 Exchange Investment Properties
                </NavbarItem>
                {/* <NavbarItem
                  href="/chalet-concierge"
                  data-id="header_how_it_works_button"
                >
                  Airbnb Investment Consulting
                </NavbarItem> */}
              </NavbarMenu>
              <Button
                style={{
                  background: "none",
                  color: "var(--tertiary)",
                  textDecoration: "underline"
                }}
                onClick={() => history.push("/sell-your-str-social")}
              >
                Sell
              </Button>
              <NavbarMenu
                href="/property-management"
                title="Manage"
                data-id="header_manage_button"
              >
                <NavbarItem
                  href="/property-management"
                  data-id="header_find-property-manager_button"
                >
                  Find A Property Manager
                </NavbarItem>
                <NavbarItem
                  href="/turnover-services"
                  data-id="header_turnover-services_button"
                >
                  Find A Turnover Service/Airbnb Cleaners
                </NavbarItem>
                {/* <NavbarItem
                  href="/remote-manage-your-airbnbs"
                  data-id="header_airbnb_consulting_button"
                >
                  Chalet Remote Host
                </NavbarItem> */}
              </NavbarMenu>

              <PopupButton
                // type={ButtonType.link}
                id="DU51pTsh"
                data-id="header-contact-us_button"
                hidden={{
                  market_of_interest: "Not Specified",
                  source_page: "desktop_navbar"
                }}
                style={{
                  background: "var(--linearGradient)",
                  color: "var(--white)",
                  textTransform: "uppercase",
                  margin: "0 auto",
                  display: "block",
                  fontSize: "1rem",
                  fontWeight: "400",
                  // textDecoration: "underline",
                  padding: "0.5rem",
                  border: 0,
                  // boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                  borderRadius: "0.25rem",
                  cursor: "pointer"
                }}
                // onClick={handleContactUsClick}
                // onMouseEnter={handleContactUsClick}
              >
                Connect With Airbnb Realtor
              </PopupButton>
              <LinkWrapper to="/blog">
                <Button
                  style={{
                    background: "none",
                    color: "var(--tertiary)",
                    fontWeight: "300"
                    // textDecoration: "underline"
                  }}
                  onClick={() => history.push("/blog")}
                >
                  Blog
                </Button>
              </LinkWrapper>
              {!user && (
                <>
                  <Button
                    type={ButtonType.link}
                    style={{
                      background: "none",
                      color: "var(--tertiary)",
                      textTransform: "uppercase",
                      textDecoration: "underline"
                    }}
                    onClick={() => setOpenLoginModal(true)}
                  >
                    Login
                  </Button>
                </>
              )}
              {user && (
                <>
                  <Button
                    type={ButtonType.link}
                    ref={buttonRef}
                    id="user-button"
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseOver}
                    style={{ textTransform: "uppercase" }}
                  >
                    {(user as any)?.attributes ? (
                      <>
                        {(user as any)?.attributes.name?.split(" ")[0]}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="var(--quinary)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-arrow-down"
                        >
                          <polyline points="6 9 12 15 18 9" />
                        </svg>
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Popover
                    ref={popoverRef}
                    color={Color.quinary}
                    isOpen={isUserOpen}
                    placement={Placement.bottomStart}
                    target="user-button"
                    onMouseLeave={handleMouseOver}
                  >
                    <NavbarMenuList>
                      <Button
                        type={ButtonType.link}
                        onClick={signOut}
                        data-id="header_logout_button"
                      >
                        Logout
                      </Button>
                    </NavbarMenuList>
                  </Popover>
                  {/* {(user as any)?.attributes && (
                    <img
                      src="./images/ProfileIcon.png"
                      alt=""
                      style={{ height: "48px" }}
                    />
                  )} */}
                </>
              )}
            </NavbarList>
          </Flex>
        )}
      </StyledNavbar>
      <ContactUsModal />
    </>
  );
};

export default Navbar;
