import { Pie } from "react-chartjs-2";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, defaults } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Colors } from "../../../components/GlobalStyle";
import { Font } from "../../../components/Text";

defaults.font.family = Font.Oswald;

ChartJS.register(ArcElement, Tooltip, ChartDataLabels);
export default function PieChart({ data }: { data: Record<string, any> }) {
  const labels: string[] = [];
  const values: number[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (key !== "market") {
      labels.push(key);
      values.push(value);
    }
  });

  return (
    <Pie
      data={{
        labels,
        datasets: [
          {
            labels,
            data: values,
            backgroundColor: [
              Colors.secondary,
              Colors.pinkRed,
              Colors.tertiary,
              Colors.seventh,
              Colors.tertiary,
              Colors.fourth,
              Colors.primary
            ]
          }
        ] as any
      }}
      options={{
        aspectRatio: 1.75,
        layout: {
          padding: {
            bottom: 16,
            top: 24,
            left: 8,
            right: 8
          }
        },
        plugins: {
          datalabels: {
            color: Colors.tertiary,
            formatter: (value, context) => {
              return (context.dataset as any).labels[context.dataIndex];
            },
            font: {
              size: 9,
              weight: "normal"
            },
            anchor: "end",
            align: "end"
          }
        }
      }}
    />
  );
}
