/* eslint-disable camelcase */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled, { css } from "styled-components";
import { v4 as uuidV4 } from "uuid";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet"; // Add this import
import Button, { StyledButton } from "../../components/Button";
import AnalyticsModal from "../Analytics/components/AnalyticsModal";
import { AppContext } from "../../components/AppContext";
import Flex from "../../components/Flex";
import InlineInput from "../../components/InlineInput";
import Metric, { StyledMetric } from "../../components/Metric";
import Section from "../../components/Section";
import { StyledSelect } from "../../components/Select";
import Text from "../../components/Text";
import { Color } from "../../types";
import StackedBarChart from "../../components/charts/StackedBarChart";
import Arrows from "../../components/Arrows";
import useCookies from "../../hooks/useCookies";
import Row from "../../components/Row";
import Column from "../../components/Column";
import BarChart from "../Analytics/components/BarChart";
// ... existing imports ...
import useLocalStorage from "../../hooks/useLocalStorage"; // Add this import
import {
  AnalyticsBox,
  AnalyticsBoxTitle,
  ChartWrapper
} from "../Analytics/components/AnalyticsBoxes";
import Header from "../../components/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableLink,
  TableRow
} from "../../components/Table";
import Banner from "../../components/Banner";
import Popover, { Placement } from "../../components/Popover";
import Accordion from "../../components/Accordion";

const formatter = (val: number, options = {}) =>
  new Intl.NumberFormat("en-us", {
    maximumFractionDigits: (options as any).style === "percent" ? 1 : 0,
    ...options
  }).format(val);

const PropertyImage = styled.img`
  height: 192px;
  width: 167px;
`;

const Address = styled.div`
  display: contents;
  font-family: "Oswald", Impact, "Franklin Gothic Bold", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.48;

  .address-1 {
    color: var(--tertiary);
  }
  .address-2 {
    color: var(--quaternary);
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Chip = styled.div<{ alternate?: boolean; noBorders?: boolean }>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  font-size: 1rem;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--quaternary);
  border-radius: 0.25rem;
  box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.1);

  ${(props) =>
    props.alternate &&
    css`
      background-color: var(--lightPurple);
      justify-content: space-between;
      border: 1px solid var(--tertiary);
    `};

  ${(props) =>
    props.noBorders &&
    css`
      border: 1px solid transparent;
    `};

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.625rem;
  }
`;

const ChipText = styled(Text)`
  align-items: center;
  display: flex;
  flex: 1 1 50%;

  &:first-of-type {
    border-right: 1px solid var(--tertiary);
  }
  &:last-of-type {
    border-left: 1px solid var(--tertiary);
    justify-content: flex-end;
  }
`;

const ChipRow = styled(Row)`
  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;

    & > ${Chip} {
      flex: 1 1 40%;
    }
  }
`;

const MetricRow = styled(Row)`
  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;

    & > ${StyledMetric} {
      flex: 1 1 45%;
    }
  }
`;

const FinancingCallout = styled.div`
  background-color: var(--lightBlue);
  box-shadow: 4px 4px 32px 0px rgba(47, 53, 75, 0.1);
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0;

  .firstText {
    color: var(--white);
    font-weight: 600;
  }

  .secondText {
    color: var(--black);
    font-weight: 600;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 0.75rem;

    .secondText {
      text-align: center;
      width: 100%;
    }
  }
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const SectionTitle = styled.h2<{ color?: string }>`
  color: ${(props) => props.color || "var(--quaternary)"};
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
`;

const Grid = styled(Flex)`
  align-items: stretch;
  padding: 0 0;
  width: 100%;

  ${StyledButton},
  ${StyledSelect},
  ${Section} {
    button {
      &:nth-child(5) {
        background-color: var(--white);
        color: var(--primary);
      }
    }
    margin: 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${Section} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2rem;

    ${StyledSelect},
    ${Section} {
      flex: 1 1 auto;
      margin: 0.5rem 0;
      button {
        &:nth-child(5) {
          background-color: var(--white);
          color: var(--primary);
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0.9375rem;

    ${StyledButton},
    ${StyledSelect},
    ${Section} {
      margin: 0.3125rem 0;
      width: 100%;
    }
  }
`;

// const PlaceholderTooltip = (
//   <>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//     <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//       This is a placeholder tooltip
//     </Text>
//   </>
// );

const ArrowsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100vh - 133px);
  right: 2rem;
`;

const InputField = styled.input`
  width: 40px;
  margin-right: 5px;
  padding: 2px;
  border: 1px solid var(--quaternary);
  border-radius: 3px;
  font-size: 1rem;
`;

const PriceInputField = styled(InputField)`
  width: 80px;
`;

const StyledShareButton = styled.div`
  align-items: center;
  color: var(--quaternary);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  gap: 0.25rem;
  text-decoration: underline;
`;

const ShareButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsOpen(true);

    setTimeout(() => setIsOpen(false), 1000);
  };
  return (
    <>
      <StyledShareButton id="share-button" onClick={copyUrl}>
        Share
        <svg
          width="23"
          height="21"
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.43334 1.5C8.85676 1.5 9.20001 1.83579 9.20001 2.25C9.20001 2.61819 8.9288 2.92441 8.57115 2.98792L8.43334 3H3.83334C2.6378 3 1.65531 3.89233 1.54386 5.03331L1.53333 5.25V17.25C1.53333 18.4195 2.44549 19.3807 3.61183 19.4897L3.83334 19.5H16.1C17.2955 19.5 18.278 18.6076 18.3895 17.4667L18.4 17.25V15.75C18.4 15.3358 18.7433 15 19.1667 15C19.543 15 19.8561 15.2654 19.921 15.6152L19.9334 15.75V17.25C19.9334 19.2382 18.3516 20.8651 16.3521 20.992L16.1 21H3.83334C1.80093 21 0.137941 19.4527 0.0081538 17.4966L0 17.25V5.25C0 3.26178 1.58169 1.63494 3.5813 1.50798L3.83334 1.5H8.43334ZM14.379 0.866732C14.379 0.195054 15.0872 -0.187381 15.6307 0.0926736L15.7443 0.162961L15.8389 0.242476L22.7344 6.87425C23.0205 7.14935 23.0755 7.57271 22.8992 7.90475L22.8223 8.02452L22.7346 8.12268L15.839 14.7565C15.3556 15.2216 14.5899 14.9739 14.4152 14.3867L14.3871 14.2558L14.379 14.1324V10.9892L13.9839 11.024C13.6022 11.0639 13.2229 11.1221 12.8457 11.1986C10.4939 11.676 8.22554 12.8668 6.03001 14.7846C5.43288 15.3063 4.50903 14.8131 4.60724 14.0251C5.35283 8.04322 8.41346 4.67901 13.6292 4.12138L14.0346 4.08402L14.379 4.06136V0.866732ZM15.9123 2.41656V5.46384L14.147 5.58C11.734 5.76908 9.95495 6.59139 8.68358 7.93278C7.65774 9.01512 6.8769 10.5261 6.41967 12.5333C8.59014 10.942 10.8826 9.94481 13.3008 9.59697L13.8337 9.53104L15.9123 9.34835V12.5823L21.1965 7.49858L15.9123 2.41656Z"
            fill="#88CDC3"
          />
        </svg>
      </StyledShareButton>
      <Popover isOpen={isOpen} placement={Placement.top} target="share-button">
        Link copied to clipboard
      </Popover>
    </>
  );
};

const libraries: any[] = ["places"];

const StrCalculatorBody = ({
  calcState: propCalcState,
  address: propAddress
}: {
  calcState?: any;
  address?: string;
}) => {
  const history = useHistory();
  const location = useLocation();
  const {
    mobileSize,
    user,
    setOpenContactModal,
    calcLat,
    calcLng,
    address,
    setAddress
  } = useContext(AppContext);
  const { getAllCookies, getCookie, setCookie } = useCookies();
  // Add this near the top of your component, with other state declarations
  const [hasSeenModal, setHasSeenModal] = useLocalStorage(
    "hasSeenContactModal",
    false
  );
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);
  // Add this near the top of your component, with other state declarations
  const [userId, setUserId] = useState<string | null>(null);
  const periodRef = useRef<Record<string, any>>({});

  const [showInstructions, setShowInstructions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAllComps, setShowAllComps] = useState(false);
  const [unsupportedMarket, setUnsupportedMarket] = useState(false);

  const [{ average_daily_rate, revenue, occupancy_rate, revpar }, setStats] =
    useState({
      average_daily_rate: undefined,
      revenue: undefined,
      gross_yield: undefined,
      occupancy_rate: undefined,
      property_tax_rate: undefined,
      revpar: undefined
    });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY || "",
    libraries
  });

  const toggleShowAllComps = () => {
    setShowAllComps(!showAllComps);
  };

  const handleAnalyticsModal = () => {
    if (!hasSeenModal) {
      setShowAnalyticsModal(true);
      setHasSeenModal(true);
    }
  };

  const [
    {
      downPayment,
      closingCosts,
      renovationCosts,
      furnishingCosts,
      purchasePrice,
      interestRate,
      homeAppreciation,
      mortgageTerm,
      occupancyRate,
      netAdr,
      revenueGrowth,
      grossRevenue1,
      otaFee,
      PMI,
      lodgingTax,
      propertyManagement,
      maintenance,
      cleaningFees,
      strLicensing,
      homeownersInsurance,
      propertyTax,
      annualUtilities,
      hoa
    },
    setProfitLoss
  ] = useState<any>({
    downPayment: 100000,
    closingCosts: 0.01,
    renovationCosts: 20000,
    purchasePrice: 500000,
    interestRate: 0.07,
    homeAppreciation: 0.05,
    mortgageTerm: 30,
    furnishingCosts: 20000,
    occupancyRate: 0.5,
    netAdr: 300,
    revenueGrowth: 0.015,
    grossRevenue1: 0.5 * 350 * 365,
    otaFee: 0.03,
    PMI: 0,
    lodgingTax: 0,
    propertyManagement: 0.1,
    maintenance: 0.01,
    cleaningFees: 0.0,
    strLicensing: 200,
    homeownersInsurance: 2500, // 0.5% of initial purchasePrice
    propertyTax: 0.01, // 1% of purchasePrice
    annualUtilities: 3750, // 0.75% of initial purchasePrice
    hoa: 0.0
  });

  const [{ beds, baths, accommodates, price }, setCalcState] = useState<any>(
    propCalcState || {
      beds: 3,
      baths: 2,
      accommodates: 8,
      price: 500000
    }
  );

  const [comps, setComps] = useState([]);
  const visibleComps = showAllComps ? comps : comps.slice(0, 5);

  useEffect(() => {
    if (propAddress) {
      setAddress(propAddress);
    }
  }, [propAddress]);

  const getPrediction = () => {
    const cookies: Record<string, string> = {};
    // let user_id: string;
    // if (user) {
    //   user_id = user!.getUsername();
    // }
    getAllCookies().forEach(([name, val]) => {
      cookies[name] = val;
    });

    if (showInstructions) {
      setShowInstructions(false);
    }

    const fetchPrediction = async () => {
      setLoading(true);
      try {
        console.log("lat lng", calcLat, calcLng);
        console.log("address", address);
        const response = await fetch(
          // `https://7sdr27znoa.execute-api.us-east-2.amazonaws.com/v2/getIncomeHistory?coordinate=(${calcLat},${calcLng})&bedrooms=${beds.value}&bathrooms=${baths.value}&apiResponseType=estimator_with_comps`,
          "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/airbnb-estimate",
          {
            method: "POST",
            body: JSON.stringify({
              latitude: calcLat,
              longitude: calcLng,
              bedrooms: beds,
              bathrooms: baths,
              address,
              userId, // Add userId to the request
              //       accommodates: accommodates.value,
              //       price: purchasePrice,
              ...cookies
            })
            // headers: {
            //   Authorization:
            //     "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImphZStjaGFsZXRAYWlyYnRpY3MuY29tIn0.2WvTDqNTdap17Xoru845wUq87mQWwhiV5shF3dOS9H4",
            //   "x-api-key": "jn719ivtlxoi9CnmHMX27gkCtg9qM2ejTw1MHYTQ"
            // }
          }
        );

        if (response.ok) {
          const prediction = await response.json();
          // await fetch(
          //   "https://gpvf4ni8qj.execute-api.us-west-2.amazonaws.com/v1/airbnb-calculator",
          //   {
          //     method: "POST",
          //     body: JSON.stringify({
          //       address,
          //       beds: beds.value,
          //       baths: baths.value,
          //       annual_revenue: prediction.averages.avg_annual_revenue,
          //       adr: prediction.averages.avg_daily_rate,
          //       occupancy_rate: prediction.averages.avg_occupancy_rate,
          //       revpar:
          //         prediction.averages.avg_daily_rate *
          //         prediction.averages.avg_daily_rate *
          //         (0.01 * prediction.average.occupancy_rate),
          //       // accommodates: accommodates.value,
          //       // price: purchasePrice,
          //       ...cookies
          //     })
          //   }
          // );
          // console.log("Result fromChalet BE", resultFromChaletBE);

          // console.log("prediction", prediction);
          setLoading(false);
          setStats({
            ...prediction.averages.annual_revenue,
            revenue: prediction.averages.avg_annual_revenue,
            average_daily_rate: prediction.averages.avg_daily_rate,
            occupancy_rate: prediction.averages.avg_occupancy_rate,
            revpar:
              prediction.averages.avg_daily_rate *
              (0.01 * prediction.averages.avg_occupancy_rate)
          });
          setProfitLoss((prevState: any) => {
            const newState = { ...prevState };
            newState.occupancyRate =
              prediction.averages.avg_occupancy_rate / 100;
            newState.netAdr = prediction.averages.avg_daily_rate;
            newState.grossRevenue1 = prediction.averages.avg_annual_revenue;
            newState.grossRevenue5 =
              newState.grossRevenue1 * (1 + revenueGrowth) ** 4;
            return newState;
          });

          setComps(prediction.comps);
          const currentPredCount = Number(
            getCookie("str_pred_query_count") || "0"
          );
          setCookie(
            "str_pred_query_count",
            String(Number(currentPredCount) + 1)
          );

          if (!user) {
            // const cookie: string | undefined = getCookie(
            //   "str_pred_query_count"
            // );

            if (!currentPredCount || Number.isNaN(Number(currentPredCount))) {
              setCookie("str_pred_query_count", String(1));
              // } else if (Number(currentPredCount) > 3) {
              //   document.body.style.overflow = "hidden";
            }
          }

          // Modify this part
          if (!hasSeenModal) {
            setTimeout(() => handleAnalyticsModal(), 8000);
          }
        } else {
          throw new Error("Market not supported");
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        setUnsupportedMarket(true);
        setIsOpen(true);
      }
    };

    if (address) {
      fetchPrediction();
    }
  };

  const loanAmount = useMemo(
    () => purchasePrice - downPayment,
    [purchasePrice, downPayment]
  );

  const mortgagePayment = useMemo(() => {
    const monthlyInterestRate = interestRate / 12;
    return (
      loanAmount *
      ((monthlyInterestRate * (1 + monthlyInterestRate) ** 360) /
        ((1 + monthlyInterestRate) ** 360 - 1))
    );
  }, [loanAmount, interestRate]);

  // const totalInvestment = useMemo(() => {
  //   return (
  //     downPayment +
  //     furnishingCosts +
  //     renovationCosts +
  //     closingCosts * purchasePrice
  //   );
  // }, [downPayment, renovationCosts, closingCosts, purchasePrice]);

  const grossRevenue5 = useMemo(
    () => grossRevenue1 * (1 + revenueGrowth) ** 4,
    [grossRevenue1, revenueGrowth]
  );
  const handleJoinAIWaitlistSubmit = async (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email,
          sourcePage: `${window.location.pathname}`
        })
      };
      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      );
      alert("Thank you for subscribing!");
    } catch (error) {
      console.error("Error submitting email:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  const calculateLoanDataAfterMonth = (month: number) => {
    let remainingLoanAmount = loanAmount;
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;
    for (let i = 0; i < month; i++) {
      const interestPaid = (remainingLoanAmount * interestRate) / 12;
      const principalPaid = mortgagePayment - interestPaid;
      remainingLoanAmount -= principalPaid;
      totalPrincipalPaid += principalPaid;
      totalInterestPaid += interestPaid;
    }

    return {
      remainingLoanAmount,
      totalPrincipalPaid,
      totalInterestPaid
    };
  };

  const feesFromRevenue = useCallback(
    (revenueLocal: number) =>
      propertyManagement * revenueLocal +
      lodgingTax * revenueLocal +
      cleaningFees * revenueLocal +
      otaFee * revenueLocal,
    [propertyManagement, lodgingTax, otaFee, grossRevenue1]
  );
  const costsOfOwnership1 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const costsOfOwnership5 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const yearlyPayments = useMemo(() => mortgagePayment * 12, [mortgagePayment]);

  const yearlyPMI = useMemo(() => PMI * 12, [PMI]);

  const otherGrossRevenues = useCallback(
    (year: number) => grossRevenue1 * (1 + revenueGrowth) ** year,
    [grossRevenue1, revenueGrowth]
  );

  const netIncome1 = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        costsOfOwnership1 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      costsOfOwnership1,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netIncomeAfter1 = useCallback(
    (revenueLocal) =>
      revenueLocal -
      (feesFromRevenue(revenueLocal) +
        costsOfOwnership5 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue5,
      costsOfOwnership5,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netOperatingIncome = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        hoa * purchasePrice +
        (maintenance + propertyTax) * purchasePrice +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      hoa,
      maintenance,
      purchasePrice,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const capRate = useMemo(
    () => netOperatingIncome / purchasePrice,
    [netOperatingIncome, purchasePrice]
  );

  const cashFlowPotential = useMemo(() => {
    if (capRate >= 0.1) return { text: "HIGH", color: "#4CAF50" }; // Green
    if (capRate >= 0.065) return { text: "MEDIUM", color: "#FFA000" }; // Amber
    return { text: "LOW", color: "#F44336" }; // Red
  }, [capRate]);

  const barChartData = React.useMemo(() => {
    const revenueData = comps.reduce((acc: any, comp: any) => {
      const revenueRange = Math.floor(comp.annual_revenue / 20000) * 20000;
      const label = `$${revenueRange / 1000}k`;
      acc[label] = (acc[label] || 0) + 1;
      return acc;
    }, {});

    const avgRevenue = revenue || 0;
    const avgBucket = Math.floor(avgRevenue / 20000) * 20000;
    const sortedBuckets = Object.keys(revenueData)
      .map((key) => parseInt(key.replace("$", "").replace("k", "000"), 10))
      .sort((a, b) => a - b);

    const orderedData: Record<string, number> = {};
    const middleIndex = sortedBuckets.findIndex(
      (bucket) => bucket >= avgBucket
    );

    sortedBuckets.forEach((bucket, index) => {
      const distance = Math.abs(index - middleIndex);
      const position = index < middleIndex ? -distance : distance;
      const label = `$${bucket / 1000}k`;
      orderedData[label] = revenueData[label];
    });

    return orderedData;
  }, [comps, revenue]);

  const appreciationAfterYear = (year: number) =>
    (purchasePrice + renovationCosts) * (1 + homeAppreciation) ** year -
    purchasePrice;

  const investmentValueAfterYear = (year: number) =>
    appreciationAfterYear(year) +
    new Array(year)
      .fill(false)
      .map((discard, idx) => {
        if (idx === 0) {
          return netIncome1;
        }
        return netIncomeAfter1(otherGrossRevenues(idx + 1));
      })
      .reduce((prev, curr) => prev + curr, 0) +
    downPayment +
    calculateLoanDataAfterMonth(year * 12).totalPrincipalPaid;

  const onChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCalcState((prevState: any) => ({
      ...prevState,
      [name]: name === "price" ? Number(value) : value
    }));

    if (name === "price") {
      const newPrice = Number(value);
      setProfitLoss((prevState: any) => ({
        ...prevState,
        purchasePrice: newPrice,
        downPayment: newPrice * 0.2, // Update down payment to 20% of new price
        homeownersInsurance: newPrice * 0.005, // Update to 0.5% of new price
        propertyTax: 0.01, // Keep at 1% (this is already a percentage)
        annualUtilities: newPrice * 0.0075 // Update to 0.75% of new price
      }));
    }

    if (["beds", "baths", "accommodates"].includes(name)) {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set("beds", name === "beds" ? value : beds);
      searchParams.set("baths", name === "baths" ? value : baths);
      searchParams.set(
        "accommodates",
        name === "accommodates" ? value : accommodates
      );
      searchParams.set("price", name === "price" ? value : price);
      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString()
      });
    }
  };

  const handleUpdate = () => {
    getPrediction();
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (e.key === "Backspace" && value.slice(-1) === "%") {
      e.preventDefault();

      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        const newValue = value.slice(0, value.length - 2);
        if (newValue === "") {
          delete periodRef.current[name];
        }
        newState[name] = Number(value.slice(0, value.length - 2)) / 100;
        return newState;
      });
    }
  };

  const onInputChange = (percent?: boolean) => (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log(name, value, periodRef.current[name]);
    if (value.slice(-1) !== ".") {
      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        let str = value.replace(/[^0-9.]/g, "");
        if (periodRef.current[name]) {
          str = str
            .split(".")
            .join("")
            .replace(
              periodRef.current[name]?.prevNumStr?.split(".")[0],
              `${periodRef.current[name]?.prevNumStr?.split(".")[0]}.`
            );
        }
        let num = Number(str);
        if (percent) {
          num /= 100;
        }
        newState[name] = num;
        return newState;
      });
    } else {
      const str = value.replace(/[^0-9.]/g, "");
      periodRef.current[name] = {
        prevNumStr: str
      };
    }
  };
  // Add this useEffect hook after your other useEffect hooks
  useEffect(() => {
    const initializeUserId = async () => {
      try {
        // Check if user is authenticated
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          setUserId(currentUser.attributes.sub);
        } else {
          // For unauthenticated users, use or create a session ID
          let sessionId = getCookie("session_id");
          if (!sessionId) {
            sessionId = uuidV4();
            setCookie("session_id", sessionId); // Set cookie to expire in 1 year
          }
          setUserId(sessionId || null);
        }
      } catch (error) {
        console.error("Error initializing user ID:", error);
      }
    };

    initializeUserId();
  }, []);

  useEffect(() => {
    if (
      address &&
      calcLat &&
      !Number.isInteger(calcLat) &&
      calcLng &&
      !Number.isInteger(calcLng)
    ) {
      getPrediction();
    }
  }, [address, calcLat, calcLng]);

  useEffect(() => {
    setCalcState(
      propCalcState || {
        beds: 3,
        baths: 2,
        accommodates: 8,
        price: purchasePrice
      }
    );
  }, [propCalcState]);

  useEffect(() => {
    if (user) {
      document.body.style.overflow = "";
    } else {
      const anonUserId = getCookie("anon_user_id");
      console.log("anonUserId in calc", anonUserId);
      const queryAmounts = getCookie("str_pred_query_count");

      if (!anonUserId && !user) {
        setCookie("anon_user_id", uuidV4());
        setCookie("str_pred_query_count", "0");
      }

      // if (queryAmounts && Number(queryAmounts) === 3 && !user) {
      //   document.body.style.overflow = "hidden";
      // }
    }
  }, [user]);

  useEffect(() => {
    if (location?.state) {
      if ((location as any).state.showInstructions) {
        setShowInstructions(true);
      } else {
        setShowInstructions(false);
      }
    } else {
      setShowInstructions(true);
    }
  }, [location]);

  const splitAddress = address.split(",");
  const street = splitAddress[0];
  const city = splitAddress[1]?.trim();
  const state = splitAddress[2]?.trim().split(" ")[0];

  const center =
    calcLat &&
    !Number.isInteger(calcLat) &&
    calcLng &&
    !Number.isInteger(calcLng)
      ? {
          lat: parseFloat(calcLat),
          lng: parseFloat(calcLng)
        }
      : {
          lat: 39.740063,
          lng: -104.939545
        };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/analytics/str-calculator"
        />
        <title>
          FREE AI-Powered Airbnb/Short-Term Rental Calculator and STR Analysis
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Unlock the full potential of your short-term rental with our free, AI-powered calculator. Get actionable insights on revenue, occupancy, ROI, and more—no sign-ups, just data-driven results."
        />
        <meta
          property="og:title"
          content="AI-Powered Airbnb Calculator: Free, Simple, and Insightful Short-Term Rental Analysis"
        />
        <meta
          property="og:description"
          content="Maximize your Airbnb investment with our advanced AI calculator. Instantly estimate revenue, occupancy, cap rates, and more with detailed insights—completely free and easy to use."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/analytics/str-calculator"
        />
        <meta property="og:site_name" content="Chalet" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/images/airbnb-calculator.webp`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="FREE Short-Term Rental (Airbnb) Calculator"
        />
        <meta
          name="twitter:description"
          content="FREE Airbnb Investment Estimator powered by Gen AI.
            Estimates on Annual Revenue, Cap Rates,
            Earning Potentials, ROI, ADR, Occupancy Rate, Cash on Cash, Yields, and more."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/images/airbnb-calculator.webp`}
        />
      </Helmet>
      <Section
        style={{
          boxShadow: "none",
          maxWidth: "100%",
          padding: mobileSize ? "1rem 0.5rem" : "1rem"
        }}
      >
        <Row
          style={{
            alignItems: "flex-end",
            gap: "1rem",
            position: "relative",
            paddingTop: "0"
          }}
        >
          <div style={{ position: "absolute", top: "0", right: "2rem" }}>
            <ShareButton />
          </div>
          <Column
            style={{
              flex: "1 1 40%",
              gap: "0.75rem"
            }}
            $noPadding
          >
            <Address>
              <div className="address-1">{splitAddress[0] || "- - -"}</div>
              <div className="address-2">
                {address !== "" ? splitAddress.slice(1).join(",") : "- - -"}
              </div>
            </Address>
            <ChipRow style={{ gap: "0.5rem", padding: "0" }}>
              <Chip>
                <InputField
                  type="number"
                  name="beds"
                  value={beds}
                  onChange={onChange}
                />
                Bedroom{beds !== 1 ? "s" : ""}
              </Chip>
              <Chip>
                <InputField
                  type="number"
                  name="baths"
                  value={baths}
                  onChange={onChange}
                />
                Bathroom{baths !== 1 ? "s" : ""}
              </Chip>
              <Chip>
                <InputField
                  type="number"
                  name="accommodates"
                  value={accommodates}
                  onChange={onChange}
                />
                Guest{accommodates !== 1 ? "s" : ""}
              </Chip>
              <Chip alternate noBorders>
                <span style={{ fontWeight: "bold" }}>$</span>
                <PriceInputField
                  type="number"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
                <Button onClick={handleUpdate}>Update</Button>
              </Chip>
            </ChipRow>
            <Row style={{ padding: "0" }} $hideMobile>
              <Chip alternate>
                <ChipText weight={700} size="1rem" paddingBottom="0">
                  Cash-flow potential:{" "}
                  <span
                    style={{
                      color: cashFlowPotential.color,
                      paddingInlineStart: "0.25rem",
                      fontWeight: "bold"
                    }}
                  >
                    {cashFlowPotential.text}
                  </span>
                </ChipText>
                <ChipText weight={700} size="1rem" paddingBottom="0">
                  Long-Term Asset Appreciation:{" "}
                  <span
                    style={{
                      color: "#FFA000",
                      paddingInlineStart: "0.25rem"
                    }}
                  >
                    MEDIUM
                  </span>
                </ChipText>
              </Chip>
              <div />
            </Row>
          </Column>
          <Column
            style={{ flex: "1 1 60%", justifyContent: "flex-end" }}
            $noPadding
          >
            <MetricRow style={{ gap: "0.5rem" }} noPadding>
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Annual Revenue"
                value={
                  revenue
                    ? formatter(revenue, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Occupancy Rate"
                value={
                  occupancy_rate
                    ? formatter(occupancy_rate / 100, {
                        style: "percent"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Average Daily Rate"
                value={
                  average_daily_rate
                    ? formatter(average_daily_rate, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Net Income"
                value={
                  address && netIncome1
                    ? formatter(netIncome1, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Cap Rate"
                value={
                  address
                    ? formatter(netOperatingIncome / purchasePrice, {
                        style: "percent"
                      })
                    : undefined
                }
              />
            </MetricRow>
          </Column>
        </Row>
        <Row style={{ gap: "0.5rem" }}>
          <Column>
            {/* <Row noPadding>
              <SectionTitle>
                <img
                  src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
                  alt="Icon"
                  style={{ marginRight: "2px", width: "25px", height: "25px" }}
                />
                AI Property Insights
              </SectionTitle>
            </Row> */}
            <Row noPadding style={{ alignItems: "center", gap: "0.5rem" }}>
              <Column style={{ flex: "1 1 35%" }}>
                <Banner
                  title="AI Property Insights Coming Soon!"
                  description="Join our waitlist to be the first to access powerful AI-driven property insights."
                  ctaText="Join Waitlist"
                  ctaAction={handleJoinAIWaitlistSubmit}
                  style={{ width: "100%", margin: "1rem 0" }}
                />
              </Column>
              <Column style={{ flex: "1 1 30%" }}>
                <AnalyticsBox $noBorder>
                  <AnalyticsBoxTitle>
                    <div>Annual Revenue Distribution</div>
                  </AnalyticsBoxTitle>
                  <ChartWrapper>
                    <BarChart data={barChartData} yGrid xAxis />
                  </ChartWrapper>
                </AnalyticsBox>
              </Column>
              <Column style={{ flex: "1 1 35%" }}>
                <AnalyticsBox
                  $noBorder
                  $decorative
                  $shadow
                  style={{
                    alignItems: "flex-start",
                    flex: "1 1 auto"
                  }}
                >
                  <Header
                    size={3}
                    style={{ alignItems: "flex-start", textAlign: "left" }}
                  >
                    Looking to buy or sell your short-term rental?
                  </Header>
                  <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                    Get paired with Chalet Preferred Partners in{" "}
                    <strong style={{ color: "var(--tertiary)" }}>
                      {city}, {state}
                    </strong>
                  </div>
                  <div style={{ padding: "0 1rem" }}>
                    <StyledPopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      className="connect-with-realtor_popup_top"
                    >
                      Connect with STR Agent
                    </StyledPopupButton>
                  </div>
                </AnalyticsBox>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row style={{ gap: "0.5rem" }} $shadow>
          <Column style={{ flex: "1 1 65%", gap: "2rem" }}>
            <Row noPadding>
              <SectionTitle color="var(--tertiary)">
                Comps Analysis
              </SectionTitle>
            </Row>
            <Row noPadding>
              <Column style={{ flex: "1 1 30%" }} $noPadding>
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={{
                      backgroundColor: "var(--white)",
                      width: mobileSize ? "343px" : "245px",
                      height: "262px"
                    }}
                    center={center}
                    zoom={10}
                  >
                    <Marker position={center} />
                    {comps.map(({ listing_id, Latitude, Longitude }) => (
                      <Marker
                        key={listing_id}
                        position={{ lat: Latitude, lng: Longitude }}
                      />
                    ))}
                  </GoogleMap>
                )}
              </Column>
              <Column style={{ flex: "1 1 70%" }} $noPadding>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell color={Color.quaternary} $longCell>
                        Name
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary} $longCell>
                        Annual Revenue
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary} $longCell>
                        Occupancy Rate
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary}>
                        ADR
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary}>
                        Beds
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary}>
                        Bathrooms
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.quaternary}>
                        Days Available
                      </TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {visibleComps.map((comp: any) => (
                      <TableRow key={comp.listing_id}>
                        <TableCell align="left" $longCell>
                          <TableLink
                            href={comp.listing_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {comp.listing_title}
                          </TableLink>
                        </TableCell>
                        <TableCell $longCell>
                          {formatter(comp.annual_revenue, {
                            style: "currency",
                            currency: "USD"
                          })}
                        </TableCell>
                        <TableCell $longCell>
                          {formatter(comp.occupancy_rate / 100, {
                            style: "percent"
                          })}
                        </TableCell>
                        <TableCell>
                          {formatter(comp.average_daily_rate, {
                            style: "currency",
                            currency: "USD"
                          })}
                        </TableCell>
                        <TableCell>{comp.Bedrooms}</TableCell>
                        <TableCell>{comp.Bathrooms}</TableCell>
                        <TableCell>{comp.days_available}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {comps.length > 5 && (
                  <div style={{ textAlign: "center", marginTop: "1rem" }}>
                    <Button onClick={toggleShowAllComps}>
                      {showAllComps ? "Show Less" : "Show More"}
                    </Button>
                  </div>
                )}
              </Column>
            </Row>
            <Row noPadding>
              <AnalyticsBox
                $noBorder
                $shadow
                $decorative
                style={{
                  alignItems: "flex-start",
                  flex: "1 1 auto",
                  padding: mobileSize ? "1rem 0" : "1rem 2rem"
                }}
              >
                <Header
                  size={mobileSize ? 4 : 2}
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    marginBottom: "0"
                  }}
                >
                  Get Short-Term Rental Financing
                </Header>
                <div>
                  <ul
                    style={{
                      margin: "0"
                    }}
                  >
                    <li>Low down payments</li>
                    <li>Financing based on your Airbnb Rental Income</li>
                    <li>Get loan rates and get pre-qualified</li>
                  </ul>
                </div>
                <div
                  style={{
                    padding: "0 1rem",
                    width: mobileSize ? "100%" : undefined
                  }}
                >
                  <FinancingCallout>
                    {!mobileSize && (
                      <div className="firstText">
                        Est Mortgage Payment $
                        {formatter(mortgagePayment, {
                          maximumFractionDigits: 0
                        })}
                        /month
                      </div>
                    )}
                    <div className="secondText">
                      Get Short-Term Rental Financing
                    </div>
                  </FinancingCallout>
                </div>
              </AnalyticsBox>
            </Row>
          </Column>
          <Column style={{ flex: "1 1 35%", gap: "0.5rem" }}>
            {/* {!mobileSize && (
              <Row noPadding>
                <SectionTitle>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
                    alt="Icon"
                    style={{
                      marginRight: "2px",
                      width: "25px",
                      height: "25px"
                    }}
                  />
                  Comps AI Insights
                </SectionTitle>
              </Row>
            )}
            , */}
            {!mobileSize && (
              <Row noPadding>
                <Column>
                  <Banner
                    title="Comps AI Insights Coming Soon!"
                    description="Get notified when our AI-powered comps analysis becomes available."
                    ctaText="Stay Informed"
                    ctaAction={handleJoinAIWaitlistSubmit}
                    style={{ width: "100%", margin: "1rem 0" }}
                  />
                </Column>
              </Row>
            )}
            <Row style={{ gap: "0.5rem" }} noPadding>
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Target Audience"
                value="Families"
              />
              <Metric
                loading={loading}
                borderColor={Color.tertiary}
                color={Color.tertiary}
                shadowColor={Color.quaternary}
                titleColor={Color.quaternary}
                title="Source of Demand"
                value="In-state"
              />
            </Row>
          </Column>
        </Row>
        <Row style={{ gap: "0.5rem" }}>
          <Accordion>
            <Column style={{ gap: "1rem" }} $noPadding>
              <Accordion.Trigger>
                <Row noPadding>
                  <SectionTitle>
                    <Accordion.Indicator>&#x25BE;</Accordion.Indicator>{" "}
                    Financial Assumptions
                  </SectionTitle>
                </Row>
              </Accordion.Trigger>
              <Accordion.Content>
                <Row noPadding>
                  <Grid>
                    <Section>
                      <Text weight={700} style={{ fontSize: "1.25rem" }}>
                        Investment
                      </Text>
                      <InlineInput
                        name="downPayment"
                        label="Initial Investment"
                        value={formatter(downPayment, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                        boldLabel
                      />
                      <InlineInput
                        name="closingCosts"
                        label="Closing Costs"
                        value={formatter(closingCosts, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="purchasePrice"
                        label="Purchase Price"
                        value={formatter(purchasePrice)}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="interestRate"
                        label="Interest Rate"
                        value={formatter(interestRate, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="homeAppreciation"
                        label="Estimated Home Appreciation"
                        value={formatter(homeAppreciation, {
                          style: "percent"
                        })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="mortgageTerm"
                        label="Mortage Term in Years"
                        value={mortgageTerm}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="furnishingCosts"
                        label="Furnishing Costs"
                        value={formatter(furnishingCosts)}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange()}
                      />
                    </Section>
                    <Section>
                      <Text weight={700} style={{ fontSize: "1.25rem" }}>
                        Income
                      </Text>
                      <InlineInput
                        name="occupancyRate"
                        label="Occupancy Rate"
                        value={formatter(occupancyRate, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="netAdr"
                        label="Net ADR"
                        value={formatter(netAdr, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="revenueGrowth"
                        label="Revenue Growth"
                        value={formatter(revenueGrowth, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="grossRevenue1"
                        label="Gross Revenue Year 1"
                        value={formatter(grossRevenue1, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                        boldLabel
                        boldValue
                        color={Color.tertiary}
                      />
                      <InlineInput
                        name="grossRevenue5"
                        label="Gross Revenue Year 5"
                        value={formatter(grossRevenue5, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                        boldLabel
                        boldValue
                        plaintext
                        color={Color.eighth}
                      />
                    </Section>
                    <Section>
                      <Text weight={700} style={{ fontSize: "1.25rem" }}>
                        Variable Expenses
                      </Text>
                      <InlineInput
                        name="mortgagePayment"
                        label="Mortage Payment"
                        value={formatter(mortgagePayment, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                        boldLabel
                        boldValue
                        plaintext
                        color={Color.eighth}
                      />
                      <InlineInput
                        name="otaFee"
                        label="OTA (Airbnb) Fee"
                        value={formatter(otaFee, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="lodgingTax"
                        label="Lodging Tax"
                        value={formatter(lodgingTax, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="propertyManagement"
                        label="Property Management"
                        value={formatter(propertyManagement, {
                          style: "percent"
                        })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="maintenance"
                        label="Maintenance"
                        value={formatter(maintenance, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="cleaningFees"
                        label="Cleaning Fees"
                        value={formatter(cleaningFees, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                    </Section>
                    <Section>
                      <Text weight={700} style={{ fontSize: "1.25rem" }}>
                        Fixed Expenses
                      </Text>
                      <InlineInput
                        name="strLicensing"
                        label="STR Licensing"
                        value={formatter(strLicensing, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="homeownersInsurance"
                        label="Homeowners Insurance"
                        value={formatter(homeownersInsurance, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="propertyTax"
                        label="Property Tax"
                        value={formatter(propertyTax, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                      <InlineInput
                        name="annualUtilities"
                        label="Annual Utilities"
                        value={formatter(annualUtilities, {
                          style: "currency",
                          currency: "USD"
                        })}
                        onChange={onInputChange()}
                      />
                      <InlineInput
                        name="hoa"
                        label="HOA"
                        value={formatter(hoa, { style: "percent" })}
                        onKeyDown={onKeyDown}
                        onChange={onInputChange(true)}
                      />
                    </Section>
                  </Grid>
                </Row>
              </Accordion.Content>
            </Column>
          </Accordion>
        </Row>
        <Row style={{ gap: "0.5rem" }}>
          <Column $noPadding>
            {!mobileSize && (
              <Row noPadding>
                <SectionTitle>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
                    alt="Icon"
                    style={{
                      marginRight: "2px",
                      width: "25px",
                      height: "25px"
                    }}
                  />
                  Financial AI Insights
                </SectionTitle>
              </Row>
            )}
            ,
            {!mobileSize && (
              <Row noPadding>
                <Column style={{ flex: "1 1 50%" }}>
                  <Banner
                    title="Financial AI Insights Coming Soon!"
                    description="Be the first to access our AI-driven financial analysis for short-term rentals."
                    ctaText="Get Early Access"
                    ctaAction={handleJoinAIWaitlistSubmit}
                    style={{ width: "100%", margin: "1rem 0" }}
                  />
                </Column>
              </Row>
            )}
          </Column>
          <Column style={{ flex: "1 1 50%" }} $shadow>
            <Flex style={{ justifyContent: "center" }}>
              <Text
                weight={300}
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--quaternary)"
                }}
              >
                Estimated Investment Value
              </Text>
            </Flex>
            <div
              style={{
                height: "450px",
                padding: mobileSize ? "0rem" : "1rem"
              }}
            >
              <StackedBarChart
                data={new Array(mobileSize ? 5 : 10)
                  .fill(false)
                  .map((discard, idx) => ({
                    year: String(new Date().getFullYear() + 1 + idx),
                    values: [
                      {
                        name: "Cummulative Net Cash Flow",
                        value: new Array(idx + 1)
                          .fill(false)
                          .map((income, incomeIdx) => {
                            if (incomeIdx === 0) {
                              return netIncome1;
                            }

                            return netIncomeAfter1(
                              otherGrossRevenues(incomeIdx + 1)
                            );
                          })
                          .reduce((prev, curr) => prev + curr, 0)
                      },
                      {
                        name: "Cummulative Equity",
                        value:
                          downPayment +
                          calculateLoanDataAfterMonth((idx + 1) * 12)
                            .totalPrincipalPaid +
                          renovationCosts
                      },
                      {
                        name: "Cummulative Appreciation",
                        value: appreciationAfterYear(idx + 1)
                      }
                    ]
                  }))}
                line={[
                  {
                    name: "Est. Investment Value",
                    values: new Array(10)
                      .fill(false)
                      .map((discard, idx) => investmentValueAfterYear(idx + 1))
                  }
                ]}
              />
            </div>
          </Column>
        </Row>
      </Section>
      {!showInstructions && (
        <ArrowsWrapper>
          <Arrows className="arrows" src="/arrows.png" alt="Continue down" />
        </ArrowsWrapper>
      )}
      {showAnalyticsModal && (
        <AnalyticsModal
          isOpen={showAnalyticsModal}
          // onClose={() => setShowAnalyticsModal(false)}
        />
      )}
    </>
  );
};

export default StrCalculatorBody;
