/* eslint-disable camelcase */
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import styled, { css } from "styled-components";
import Markdown from "markdown-to-jsx";
import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import Flex from "../../components/Flex";
import GaugeChart from "./components/GaugeChart";
import { AppContext } from "../../components/AppContext";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import LiveMarkets from "../../liveMarkets";
import {
  compactCurrencyNotation,
  currencyNotation,
  percentNotation
} from "../../utils/numberFormatters";
import GrossYield from "./components/GrossYield";
import { Colors } from "../../components/GlobalStyle";
import Header from "../../components/Header";
import fetcher from "../../utils/fetch";
import AnalyticsModal from "./components/AnalyticsModal";
import SkeletonLoader from "../../components/SkeletonLoader";

export interface Amenities {
  "Home Theater": string;
  "Free Parking": string;
  Sauna: string;
  Pool: string;
  Gym: string;
  "Hot Tub": string;
  "Pet Friendly": string;
  Balcony: string;
}

export interface Hosts {
  host_name: string;
  total_listings: string;
  avg_rating: number;
  city: string;
  data_of_analysis: string;
}

export interface RateData {
  market: number;
  Studio: number;
  "1 Bedrooms": number;
  "2 Bedrooms": number;
  "3 Bedrooms": number;
  "4 Bedrooms": number;
  "5 Bedrooms+": number;
}

export interface MidTermRentalMarketData {
  market: string;
  updated: string;
  annual_revenue: number;
  annual_revenue_max: number;
  supply: RateData;
  monthly_rate: RateData;
  property_tax: number;
  "mid-term-rental-regulation": string;
  // occupancy_rate: RateData;
  gross_yield: string;
  us_gross_yield_ranking: string;
  unemployment_rate: Record<string, string>;
  zillow_home_value_data: Record<string, number>;
  // host_info: Hosts[];
  most_popular_amenities: Amenities;
  // demand_source: Record<string, string>;
  row_one_text: Record<string, string>;
  row_two_text: Record<string, string>;
  row_three_text: Record<string, string>;
}

const AnalyticRow = styled(Flex)<{ $reverse?: boolean }>`
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin: 0 2rem;
  padding: 1rem;

  ${(props) =>
    props.$reverse &&
    css`
      flex-direction: row-reverse;
    `};

  &:not(:last-child) {
    border-bottom: 1px solid var(--quaternary);
  }

  @media (max-width: 415px) {
    flex-direction: column;
    margin: 0;
  }
`;

const AnalyticsTitle = styled(Flex)`
  border-bottom: 1px solid var(--quaternary);
  justify-content: flex-end;
  flex: 1 1 100%;
  gap: 1rem;
  margin: 0 2rem;
  padding: 1rem;

  .analytics-title-updated,
  .analytics-title-market,
  .analytics-title-description,
  .analytics-beta {
    font-weight: 500;
  }

  .analytics-title-updated,
  .analytics-title-market {
    color: var(--quaternary);
  }
  .analytics-beta {
    color: var(--white);
    background: var(--quinary);
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    margin-left: -0.75rem;
  }

  .analytics-title-description {
    color: var(--tertiary);
    position: relative;

    &:before,
    &:after {
      background-color: var(--tertiary);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:before {
      left: -0.5rem;
    }

    &:after {
      right: -0.5rem;
    }
  }

  @media (max-width: 720px) {
    justify-content: center;
    margin: 0;
  }
`;

const AnalyticSection = styled(Flex)<{
  $column?: boolean;
  $halfColumn?: boolean;
  $third?: boolean;
  $verticalSplit?: boolean;
}>`
  align-items: flex-start;
  flex: 1 1 100%;
  text-align: left;

  ${(props) =>
    props.$third &&
    css`
      flex: 1 1 150%;
    `};

  ${(props) =>
    props.$halfColumn &&
    css`
      flex: 1 1 50%;
    `};

  ${(props) =>
    props.$column &&
    css`
      flex-direction: column;
      justify-content: flex-start;
    `};

  ${(props) =>
    props.$verticalSplit &&
    css`
      align-self: stretch;
      gap: 0.5rem;
    `};

  p {
    margin: 0 0 1rem;
  }

  @media (max-width: 815px) {
    flex: 1 1 calc(50% - 2rem);
  }

  @media (max-width: 410px) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const AnalyticsBox = styled(Flex)<{
  $decorative?: boolean;
  $knockout?: boolean;
  $knockoutBlue?: boolean;
  $knockoutPurple?: boolean;
  $knockout5?: boolean;
  $split?: boolean;
}>`
  border: 1px solid var(--quaternary);
  border-radius: 0.25rem;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0.5rem;
  position: relative;
  width: 100%;

  ${(props) =>
    props.$decorative &&
    css`
      background: url("/images/analytics_decorative.webp");
      background-size: cover;
      padding-top: 2rem;
    `}

  ${(props) =>
    props.$knockout &&
    css`
      background: var(--linearGradient);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockout5 &&
    css`
      background: var(--linearGradient5);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      background: var(--lightPurple);
      color: var(--tertiary);
    `}
    ${(props) =>
    props.$knockoutPurple &&
    css`
      background: var(--tertiary);
      color: var(--white);
    `}
`;

const AnalyticsBoxTitle = styled(Flex)<{
  $knockout?: boolean;
  $knockoutBlue?: boolean;
}>`
  border-bottom: 1px solid var(--quaternary);
  color: var(--tertiary);
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  padding: 0.25rem;
  width: 100%;

  ${(props) =>
    props.$knockout &&
    css`
      border-bottom: 1px solid var(--white);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      border-bottom: 1px solid var(--quaternary);
      color: var(--quaternary);
    `}

  .analytics-box-title-subtitle {
    font-weight: bold;
  }
`;

const AnalyticsSplitWrapper = styled(Flex)`
  align-items: stretch;
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 415px) {
    flex-direction: column;
  }
`;

const AnalyticsTextWrapper = styled(Flex)`
  align-items: flex-start;
  padding: 0 0.5rem;
  flex-direction: column;
  flex: 1 1 100%;

  strong {
    color: var(--quaternary);
  }
`;

const ChartWrapper = styled.div<{ $text?: boolean; $textBlue?: boolean }>`
  flex: 1 1 auto;
  position: relative;
  width: 100%;

  ${(props) =>
    (props.$text || props.$textBlue) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      padding: 1rem;
      line-height: 1;
      text-decoration: underline;
    `};

  ${(props) =>
    props.$text &&
    css`
      font-size: 2rem;
      color: var(--quaternary);
    `};
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default function AnalyticsMidTermRentalsMarket() {
  const { market } = useParams<Record<string, string>>();
  const { mobileSize } = useContext(AppContext);

  const urlSlug = React.useMemo(
    () =>
      market
        ? `https://www.getchalet.com/analytics/mid-term-rentals/${market}`
        : "https://www.getchalet.com/analytics",
    [market]
  );

  const { data, isLoading } = useSWR<MidTermRentalMarketData>(
    `https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/mtr/${market}`,
    fetcher
  );

  let currentDate = new Date();
  let currentMonth = new Date();

  if (data) {
    const splitUpdated = data.updated.split(" ");
    currentDate = new Date();
    currentDate.setMonth(
      months.findIndex((month) => month === splitUpdated[0])
    );
    currentDate.setFullYear(Number(splitUpdated[1]));
    currentMonth = new Date(currentDate.getTime());
    currentMonth.setMonth(currentMonth.getMonth());
  }

  const titleString = `Investor Guides To Mid-Term Rentals | ${
    LiveMarkets[market] || ""
  }`;
  const descriptionString = `Access key data for mid-term rental investments in the ${
    LiveMarkets[market] || ""
  } market with our dashboard. Get property values, market trends,return on investment and rental yields for informed decisions`;

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <AnalyticsHeader />
      {isLoading && (
        <AnalyticsWrapper marginTop={false}>
          <SkeletonLoader />
        </AnalyticsWrapper>
      )}
      {!isLoading && data === undefined && (
        <AnalyticsWrapper>
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            No data.
          </Flex>
        </AnalyticsWrapper>
      )}
      {!isLoading && data !== undefined && (
        <>
          <AnalyticsTitle>
            <span className="analytics-title-updated">{data.updated}</span>
            <span className="analytics-title-description">
              Mid-Term Rental Market Update
            </span>
            <span className="analytics-title-market">{data.market}</span>
            <span className="analytics-beta">Beta</span>
          </AnalyticsTitle>
          <AnalyticRow>
            <AnalyticSection $column $third>
              <AnalyticsTextWrapper>
                <Markdown>
                  {Object.values(data.row_one_text).join("\n\n")}
                </Markdown>
              </AnalyticsTextWrapper>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox $knockout5>
                <AnalyticsBoxTitle $knockout>
                  Yearly Income Potential
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <GaugeChart
                    data={[data.annual_revenue, data.annual_revenue_max]}
                    title={[
                      "Annual Revenue",
                      currencyNotation(data.annual_revenue, 2)
                    ]}
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox $knockoutBlue>
                <AnalyticsBoxTitle>
                  <div>Total Active Mid Term Rentals</div>
                  <div className="analytics-box-title-subtitle">
                    {data.supply.market}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <PieChart data={data.supply as any} />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection>
              <AnalyticsBox $knockoutPurple>
                <AnalyticsBoxTitle $knockout>
                  <div style={{ color: "var(--white)" }}>Monthly Rate</div>
                  <div
                    style={{ color: "var(--white)" }}
                    className="analytics-box-title-subtitle"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0
                    }).format(data.monthly_rate.market as number)}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    data={data.monthly_rate as any}
                    lighter
                    // yGrid
                    yAxisStyle={{
                      color: Colors.white,
                      fontWeight: "normal"
                    }}
                    xAxis
                  />
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
          </AnalyticRow>
          <AnalyticRow $reverse>
            <AnalyticSection $column $third>
              <AnalyticsTextWrapper>
                <Markdown>
                  {Object.values(data.row_two_text).join("\n\n")}
                </Markdown>
              </AnalyticsTextWrapper>
              <AnalyticsBox
                $decorative
                style={{
                  alignItems: "flex-start",
                  flex: "1 1 auto"
                }}
              >
                <Header
                  size={4}
                  style={{ alignItems: "flex-start", textAlign: "left" }}
                >
                  Real Estate Agents With
                  <br />
                  Mid-Term Rental Investing Experience
                </Header>
                <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                  Let our agents guide you to{" "}
                  <strong style={{ color: "var(--quaternary)" }}>
                    the best mid-term rental
                  </strong>{" "}
                  investments in {LiveMarkets[market]}
                </div>
                <div style={{ padding: "0 1rem" }}>
                  <StyledPopupButton
                    id="DU51pTsh"
                    // open="load"
                    // data-tf-lazy="false"
                    shareGaInstance="true"
                    hidden={{
                      market_of_interest:
                        LiveMarkets[market] || "Not Specified",
                      source_page: urlSlug
                    }}
                    className="connect-with-realtor_popup_top"
                  >
                    Connect with Mid-Term Rental Realtor
                  </StyledPopupButton>
                </div>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection $column $halfColumn $verticalSplit>
              <AnalyticsBox $split>
                <AnalyticsBoxTitle>Property Tax</AnalyticsBoxTitle>
                <ChartWrapper $text>
                  {percentNotation(data.property_tax, 2)}
                </ChartWrapper>
              </AnalyticsBox>
              <AnalyticsBox $split $knockoutBlue>
                <AnalyticsBoxTitle $knockoutBlue>
                  Airbnb Regulation
                </AnalyticsBoxTitle>
                <ChartWrapper $textBlue>
                  {data["mid-term-rental-regulation"]
                    .split("-")
                    .map((str) => str[0].toUpperCase() + str.slice(1))
                    .join(" ")}
                </ChartWrapper>
              </AnalyticsBox>
            </AnalyticSection>
            <AnalyticSection $column>
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>
                    Zillow Median Home Value{" "}
                    {`(${
                      months[currentMonth.getMonth()]
                    } ${currentMonth.getFullYear()})`}
                  </div>
                  <div className="analytics-box-title-subtitle">
                    {compactCurrencyNotation(
                      data?.zillow_home_value_data?.market || 0,
                      1
                    )}
                  </div>
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  {data.zillow_home_value_data && (
                    <LineChart
                      data={data.zillow_home_value_data as any}
                      min={null}
                    />
                  )}
                </ChartWrapper>
              </AnalyticsBox>
              <AnalyticsSplitWrapper style={{ marginTop: "0.5rem" }}>
                <AnalyticsBox $knockout5 $split>
                  <GrossYield
                    grossYield={data.gross_yield}
                    ranking={data.us_gross_yield_ranking}
                  />
                </AnalyticsBox>
                <AnalyticsBox $split $knockoutBlue>
                  <AnalyticsBoxTitle $knockoutBlue>
                    <div>Unemployment Rate</div>
                    <div className="analytics-box-title-subtitle">
                      <div>{data.unemployment_rate.market}</div>
                    </div>
                  </AnalyticsBoxTitle>
                  <ChartWrapper $textBlue>
                    <LineChart
                      color={Colors.tertiary}
                      data={data.unemployment_rate as any}
                      min={null}
                      y
                      percent
                    />
                  </ChartWrapper>
                </AnalyticsBox>
              </AnalyticsSplitWrapper>
            </AnalyticSection>
          </AnalyticRow>
          <AnalyticRow>
            <AnalyticSection>
              {/* <Flex
                style={{
                  // alignItems: "stretch",
                  gap: "0.5rem",
                  height: "25%",
                  width: "100%"
                }}
              > */}
              <AnalyticsBox>
                <AnalyticsBoxTitle>
                  <div>Most Popular Amenities</div>
                  <div className="analytics-box-title-subtitle" />
                </AnalyticsBoxTitle>
                <ChartWrapper>
                  <BarChart
                    aspectRatio={mobileSize ? 1 : 4}
                    sort="values"
                    data={data.most_popular_amenities as any}
                    horizontal
                    stacked
                    dataType="percent"
                    yAxisStyle={{
                      color: Colors.primary,
                      fontWeight: "normal"
                    }}
                  />
                </ChartWrapper>
              </AnalyticsBox>
              {/* </Flex> */}
            </AnalyticSection>
            {/* <AnalyticSection $column $verticalSplit /> */}
          </AnalyticRow>
        </>
      )}
      <AnalyticsModal />
    </AnalyticsWrapper>
  );
}
