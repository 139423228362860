import { Route, Redirect, Switch } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
// import Home2 from "./pages/Home2";
import Blog from "./pages/Blog";
import InvestorGuides from "./pages/InvestorGuides";
import BestAirbnbs from "./pages/BestAirbnbs";
import RentalRegulations from "./pages/RentalRegulations";
import PropertyManagement from "./pages/locations/PropertyManagement";
import ChaletRemoteHost from "./pages/ChaletRemoteHost";
import STRResources from "./pages/STRResources";
import TurnoverServices from "./pages/TurnoverServices";
import ListingTitleGenerator from "./pages/ListingTitleGenerator";
import CostSegCalculator from "./pages/CostSegCalculator";
import Service1031Exchange from "./pages/1031Exchange";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import StrCalculator from "./pages/StrCalculator";
import NotFound from "./pages/NotFound";
import ChaletConcierge from "./pages/ChaletConcierge";
import CostSegregation from "./pages/CostSegregation";
import DownloadReport from "./pages/DownloadReport";
import DownloadReportV2 from "./pages/DownloadReportV2";
import STRUnderwritingTool from "./pages/STRUnderwritingTool";
import FortLauderdale from "./pages/FortLauderdale";
import RealEstateAgents from "./pages/locations/RealEstateAgents";
import SellingYourSTR from "./pages/locations/SellingYourSTR";
import Analyze from "./pages/Analyze";
import Buy from "./pages/Buy";
import Columbus from "./pages/Columbus";
import Poconos from "./pages/Poconos";
import PalmBeach from "./pages/PalmBeach";
import Hollywood from "./pages/Hollywood";
import Atlanta from "./pages/Atlanta";
import SanDiego from "./pages/SanDiego";
import Dallas from "./pages/Dallas";
import MiamiBeach from "./pages/MiamiBeach";
import PalmSprings from "./pages/PalmSprings";
import PhoenixScottsdale from "./pages/PhoenixScottsdale";
import SummitCounty from "./pages/SummitCounty";
import Philadelphia from "./pages/Philadelphia";
import Memphis from "./pages/Memphis";
import AnalyticsRoute from "./pages/Analytics/AnalyticsRoute";
import ListingsPage from "./pages/ForSaleListings/Listings";
import IndividualListingPage from "./pages/ForSaleListings/IndividualListing";
import SellingYourSTRForSocial from "./pages/locations/SellingYourSTRForSocial";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    {/* <Route path="/home-2" component={Home2} /> */}
    {/* <Route path={["/contact", "/sign-up", "/login"]} component={Home} /> */}
    <Route path="/blog/:slug?" component={Blog} />
    <Route path="/best-airbnbs/:slug?" component={BestAirbnbs} />
    <Route
      path="/investor-guides/broward-county-fl-investor-guide-airbnb-rentals"
      exact
      render={() => <Redirect to="/investor-guides/fort-lauderdale-fl" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-kissimmee-florida"
      exact
      render={() => <Redirect to="/rental-regulations/kissimmee-fl/" />}
    />
    <Route
      path="/str-calculator"
      exact
      render={() => <Redirect to="/analytics/airbnb-calculator" />}
    />
    {/* <Route
      path="/rental-regulations/:slug?"
      render={({ match }) => {
        const { slug } = match.params;
        return <Redirect to={`/analytics/rental-regulations/${slug || ""}`} />;
      }}
    /> */}
    {/* <Route
    //   path="/investor-guides/:slug?"
    //   render={({ match }) => {
    //     const { slug } = match.params;
    //     return <Redirect to={`/analytics/investor-guides/${slug || ""}`} />;
    //   }}
    // /> */}

    <Route
      path="/rental-regulations/palm-springs-ca-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/palm-springs-ca/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-pompano-beach-fl"
      exact
      render={() => <Redirect to="/rental-regulations/pompano-beach-fl/" />}
    />
    <Route
      path="/rental-regulations/broward-county-fl-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/fort-lauderdale-fl/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-panama-city-beach-fl"
      exact
      render={() => <Redirect to="/rental-regulations/panama-city-beach-fl/" />}
    />
    <Route
      path="/rental-regulations/dallas-tx-rental-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/dallas-tx/" />}
    />
    <Route
      path="rental-regulations/short-term-rental-regulation-san-antonio-tx/"
      exact
      render={() => <Redirect to="/rental-regulations/san-antonio-tx/" />}
    />
    <Route
      path="/rental-regulations/hollywood-fl-str-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/hollywood-fl/" />}
    />
    <Route
      path="/rental-regulations/shelby-county-str-regulation"
      exact
      render={() => <Redirect to="/rental-regulations/memphis-tn/" />}
    />
    <Route
      path="/rental-regulations/philadelphia-pa-str-regulations"
      exact
      render={() => <Redirect to="/rental-regulations/philadelphia-pa/" />}
    />
    <Route
      path="/rental-regulations/short-term-rental-regulation-miami-beach-florida"
      exact
      render={() => <Redirect to="/rental-regulations/miami-beach-fl/" />}
    />
    <Route path="/investor-guides/:slug?" component={InvestorGuides} />
    {/* <Route path="/investor-guides/:slug?" component={InvestorGuides} /> */}
    <Route path="/rental-regulations/:slug?" component={RentalRegulations} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/str-calculator" component={StrCalculator} />
    <Route path="/airbnbs-for-sale" component={ListingsPage} />
    {/* <Route
      path="/airbnbs-for-sale-listings/:id"
      component={IndividualListingPage}
    /> */}
    <Route
      path="/airbnbs-for-sale-listings/:encodedAddress"
      component={IndividualListingPage}
    />
    <Route
      path="/1031-exchange/:marketPathParam?"
      component={Service1031Exchange}
    />
    <Route path="/turnover-services" component={TurnoverServices} />
    <Route path="/str-resources" component={STRResources} />
    <Route path="/analytics/:type/:market?" component={AnalyticsRoute} />
    <Route path="/remote-manage-your-airbnbs" component={ChaletRemoteHost} />
    <Route
      path="/real-estate-agents/:marketPathParam?"
      component={RealEstateAgents}
    />
    <Route path="/sell-your-str/:marketPathParam?" component={SellingYourSTR} />
    <Route path="/sell-your-str-social" component={SellingYourSTRForSocial} />
    <Route
      path="/cost-segregation/:marketPathParam?"
      component={CostSegregation}
    />
    <Route
      path="/property-management/:marketPathParam?"
      component={PropertyManagement}
    />
    <Route path="/listing-title-generator" component={ListingTitleGenerator} />
    <Route path="/cost-seg-calculator" component={CostSegCalculator} />
    <Route
      path="/download-report/:marketPathParam?"
      component={DownloadReport}
    />
    <Route path="/download-report" component={DownloadReportV2} />
    <Route path="/str-underwriting-tool" component={STRUnderwritingTool} />
    <Route path="/chalet-concierge" component={ChaletConcierge} />
    <Route path="/fort-lauderdale-fl" component={FortLauderdale} />
    <Route path="/columbus-oh" component={Columbus} />
    <Route path="/poconos-pa" component={Poconos} />
    <Route path="/hollywood-fl" component={Hollywood} />
    <Route path="/palm-beach-fl" component={PalmBeach} />
    <Route path="/memphis-tn" component={Memphis} />
    <Route path="/philadelphia-pa" component={Philadelphia} />
    <Route path="/palm-springs-ca" component={PalmSprings} />
    <Route path="/san-diego-ca" component={SanDiego} />
    <Route path="/miami-beach-fl" component={MiamiBeach} />
    <Route path="/phoenix-scottsdale-az" component={PhoenixScottsdale} />
    <Route path="/analyze" component={Analyze} />
    <Route path="/buy" component={Buy} />
    <Route path="/atlanta-ga" component={Atlanta} />
    <Route path="/summit-county-co" component={SummitCounty} />
    <Route path="/dallas-tx" component={Dallas} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
