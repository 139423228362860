import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Flex from "../../components/Flex";
import { AppContext } from "../../components/AppContext";
import StrCalculatorBody from "../StrCalculator/StrCalculatorBody";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AddressAutoComplete from "../../components/AddressAutoComplete";
import Header from "../../components/Header";

const ResponsiveFlex = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  // margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 95vw;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media (max-width: 800px) {
    // flex-direction: row;
    display: block;
  }
`;

const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 1.5rem;
  color: var(--tertiary);
  // margin-bottom: 1rem;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default function AnalyticsAirbnbCalculator() {
  const history = useHistory();
  const { mobileSize, prerenderHeight, setPrerenderHeight } =
    useContext(AppContext);
  const { setCalcLat, setCalcLng } = React.useContext(AppContext);
  const [address, setAddress] = React.useState("");
  const [calcState, setCalcState] = React.useState<any>(undefined);

  const onChange = ({ calcLat, calcLng, value }: any) => {
    setAddress(value);
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("address", value);
    searchParams.set("calcLat", calcLat);
    searchParams.set("calcLng", calcLng);
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString()
    });
  };

  React.useLayoutEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const newAddress = searchParams.get("address");
    const calcLat = searchParams.get("calcLat");
    const calcLng = searchParams.get("calcLng");
    const beds = searchParams.get("beds");
    const baths = searchParams.get("baths");
    const accommodates = searchParams.get("accommodates");
    const price = searchParams.get("price");
    if (newAddress) {
      setAddress(newAddress);
    }
    if (calcLat) {
      setCalcLat(calcLat);
    }
    if (calcLng) {
      setCalcLng(calcLng);
    }

    if (beds || baths || accommodates || price) {
      setCalcState({
        beds,
        baths,
        accommodates,
        price
      });
    }
  }, []);

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <AnalyticsHeader />
      <ResponsiveFlex>
        {/* {!mobileSize && (
          <StyledHeader size={1}>
            FREE AI-Powered Airbnb Calculator: Free, Simple, and Insightful
            Short-Term Rental Analysis
          </StyledHeader>
        )} */}

        <StyledHeader size={1}>FREE AI-Powered Airbnb Calculator</StyledHeader>

        <AddressAutoComplete
          name="address"
          onChange={onChange}
          placeholder="Enter an address to analyze ..."
          value={address}
          data-id="airbnb-calculator_address_input_field"
        />
      </ResponsiveFlex>
      <StrCalculatorBody address={address} calcState={calcState} />
    </AnalyticsWrapper>
  );
}
