import styled from "styled-components";
import Section from "./Section";

const Post = styled(Section)`
  overflow: inherit;
  max-width: 90vw;
  padding: 3rem 2rem;
  box-shadow: none;
  border-radius: 0.50rem;
  h1, h2, h3, h4 {
    text-align: left;
  }
  
  figure {
    margin: 0;
  }

  figcaption {
    text-align: center;
    color: var(--eighth);
    font-size: 1rem;
    margin-top: 0.5rem;
    font-style: italic;
  }
  .main-content {
    flex: 1;
    max-width: 100%;
  }

  .recommended-posts {
    width: 20%;
    margin-left: 2rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 32px 0px;
    background-color: var(--lightPurple);
    border-radius: 0.5rem;
    // background: #e1e5f3;
    // box-shadow: 0 0 32px rgb(0 0 0 / 15%);
    border: 1px solid var(--tertiary);
    li {
      margin-bottom: 2rem;
      // box-shadow: 0 0 32px rgb(0 0 0 / 15%);
      padding: 1rem;
      border-radius: 0.5rem;
      background: var(--white);
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
      div.post-content {
        text-decoration: underline;
        font-weight: 300;
        color: var(--quinary);
        font-size: 1.2rem;
        text-align: center;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    a {
      color: var(--tertiary);
      text-decoration: none;
      font-weight: 300;
      font-size: 1.1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .size-full img {
    max-height: 400px;
    width: auto;
    margin: 0 auto;
    display: block;
    padding: 2rem;
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 1rem 0 0;
    font-size: 1.2rem;
    // box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    // border: 1px solid var(--tertiary);
    overflow: hidden;
  }
  th, td {
    padding: 0.25rem;
    text-align: left;
    
    // border-bottom: 1px solid var(--gray);
  }
  
  # style caption
  
  th {
    background-color: var(--lightPurple);
    color: var(--tertiary);
    font-weight: 400;
    text-transform: uppercase;
  }

  // tr:last-child td {
  //   border-bottom: none;
  // }

  tr{
    background-color: var(--white);
  }

  // tr:nth-child(odd) {
  //   background-color: var(--white);
  // }
  tr:hover {
    background-color: rgba(var(--secondary-rgb), 0.1);
  }

  h1 {
    text-align: center;
  }
  td {
    border: none;
    border-bottom: 1px solid var(--tertiary);
  }
  li {
    color: var(--tertiary);
    font-weight: 200;
  }
  ul {
    margin: 0;
    padding-bottom: 1rem;
  }
  .has-text-align-right {
    text-align: right; 
  }
  h4 {
    margin: 0;
  }
  p#thank-you-note {
    display: none;
    text-align: center;
  }
  p#error-note {
    display: none;
    text-align: center;
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
    margin-top: 2rem !important;
  }
  #download_report_v2_cta {
    padding: 0;
    > div.wp-block-group__inner-container {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      display: block;
      padding: 0rem;
      margin-top: 0rem;
      margin-bottom: 0 !important;
    }
    p {
      padding-bottom: 0 !important;
    }
    #download-report-top-button {
      margin: 0 !important;
      background: var(--quinary);
      box-shadow: 0 0 32px rgb(0 0 0 / 15%);
      max-width: 100%;
    }
    #email_for_download_report {
      // padding: 1rem;
      // margin-right: 1rem;
      box-shadow: 0 0 32px rgb(0 0 0 / 15%);
      padding: 1rem;
      display: block;
      border-radius: 0.25rem ;
      margin: 1rem 0;
      // max-width: 500px;
      width: 100%;
      font-size: 1.3rem;
      text-align: left;
      border: none;
      // border-right: 0;
      // background: var(--tertiary);
      // color: var(--white);
    } 
    #name_for_download_report {
      // padding: 1rem;
      box-shadow: 0 0 32px rgb(0 0 0 / 15%);
      margin-right: 0.5rem;
      padding: 1rem;
      display: block;
      width: 100%;
      border-radius: 0.25rem ;
      margin: 1rem 0;
      // max-width: 500px;
      border: none;
      font-size: 1.3rem;
      text-align: left;
      
    }
    
  }
  #consent_policy {
    font-weight: 300;
    font-size: 0.8rem;
    margin-top: -0.9rem;
    margin-bottom: 2rem;
    text-align: center;
    a {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
    }
  }
  #three-images  {
    .wp-block-columns {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  #content-group {
   
    > div.wp-block-group__inner-container {
      display: grid;
      grid-template-columns: 2fr 2fr;
      align-items: center;
    }

  }
  #intro-split-view, .split-view {
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    img {
      max-height: 100% !important;
      width: 100%;
      height: auto;
      display: block;
      border-radius: 0.5rem;
      padding: 1.5rem;
      box-shadow: 0 0 32px 0px rgb(188 106 129 / 18%);
    }
  }
  div.split-view:nth-of-type(even) {
    grid-template-columns: 2fr 3fr;
    > div:nth-child(2) {
      grid-row-start: 1
    }
  }
  div.split-view:first-of-type, div#intro-split-view:first-of-type {
    box-shadow: none;
    padding: 0;
    > div {
      padding: 0 1rem 0 0 !important;
      p {
        
        color: var(--tertiary);
        font-weight: 300;
        font-size: 1.3rem;
      }
    }
  }
  .split-view {
    padding: 1rem;
    margin: 2rem 0rem;
    border-radius: 0.25rem;
    grid-template-columns: 3fr 2fr;
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    > div {
      padding: 0 0 0 1rem;
      // p:first-of-type {
      //   font-weight: 300;
      //   color: var(--terti);
      //   font-size: 1.4rem;
      // }
    }
  }
  #realtor-cta {
    
    border-radius: 0.25rem;
    padding: 1rem;
    p#realtor-cta-headline {
      font-size: 1.3rem;
      margin: 1rem 0rem;
    }
    h2 {
      margin: 0;
      line-height: 2.5rem;
    }
    h2:first-of-type {
      margin-top: 1rem;
    }
    figure#logo {
      img {
        width: 100px;
      }
    }
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
    
    div.wp-block-group__inner-container {
      margin: 0;
      padding: 1rem;
      box-shadow: none;
      div.wp-block-columns {
        display: grid;
        grid-template-columns: 4fr 2fr;
      }
    }
    div.wp-block-column {
      padding: 0;
    }
    #realtor-cta-buttons {
      padding: 0;
      div.wp-block-group__inner-container {
        display: flex;
        p {
          box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
          margin: 0rem;
          padding: 0;
          margin-right: 1rem;
          border-radius: 0.25rem;
          a {
            padding: 1rem;
            display: block;
            color: var(--white);
            text-decoration: none;
            cursor: pointer;
            
          }
        }
        p:first-child {
          background: var(--tertiary);
        }
        p:last-child {
          background: var(--quinary);
        }
      }
    }
  }
  strong {
    font-weight: 400;
    color: var(--quinary);
  }
  .no-bottom-padding {
    padding-bottom: 0;
  }
  h1 {
    font-size: 2.4rem;
    color: var(--tertiary);
    margin-top: 0;
    margin-bottom: 0;
    // text-align: center;
    padding: 0rem;
    padding-bottom: 1rem;
    width: 100%;
    font-weight: 400;
  }
  h2 { 
    font-size: 2rem;
    color: var(--tertiary);
    margin-top: 2rem;
    margin-bottom: 1rem;
    // text-align: center;
    // padding: 0rem;
    // padding: 2rem;
    width: 100%;
    font-weight: 400;
  }
  h3 {
    font-size: 1.7rem;
    color: var(--tertiary);
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    padding: 0rem;
    padding-bottom: 1rem;
    width: 100%;
    font-weight: 400;
  }

  h4 {
    color: var(--tertiary);
    font-weight: 400 !important;
    font-size: 1.8rem;
  }
  p {
    color: var(--tertiary);
    line-height: 2rem;
    font-weight: 300;
    text-align: left;
    padding-bottom: 1rem;
    font-size: 1.3rem;
    margin: 0;
  }
  a {
    text-decoration: "underline";
    color: var(--tertiary);
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }
  #cta {
    margin: 3rem auto;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    div.wp-block-group__inner-container {
      padding: 2rem;
    }
    h2 {
      color: var(--white);
      text-align: center;
      strong {
        color: var(--white);
        text-decoration: underline;
      }
    }
    background: var(--quinary);
    border-radius: 0.25rem;
    p {
      color: var(--white);
      width: 80%;
      margin: 0 auto;
    }
    a {
      font-weight: 400;
      color: var(--quinary);
      padding: 1rem 3rem;
      display: block;
      width: fit-content;
      border-radius: 0.25rem;
      text-decoration: none;
      margin: 0 auto;
      background: var(--white);
    }
  }
  p.download-report {
    text-align: right;
    a {
      text-decoration: "underline";
    }
  }
  td {
    font-weight: 300;
  }
  #previous_reports {
    text-align:right;
    text-decoration: underline;
  }
  #free-market-reports-cta {
    > div.wp-block-group__inner-container {
      margin: 0px auto;
      padding: 1rem;
      h2 {
        margin-top: 0;
        // text-transform: uppercase;
      }
      h3 {
        font-weight: 200;
        font-size: 1.5rem;
        text-align: center;
      }
      > p {
        text-align: center;
        margin-bottom: 1rem;
      }
      img {
        margin: 0rem auto;
        max-width: 750px;
        width: 100%;
        display: block;
        padding: 1rem;
        // box-shadow:rgb(67 73 94 / 27%) 0px 0px 43px;
        background: var(--white);
        box-shadow: 8px 8px 0px 0px var(--quinary);
        border: 1px solid var(--tertiary);

      }
      div#download_report_v2_cta {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
      }
      #content-group {
        > div {
          padding: 1rem;
          grid-template-columns: 3fr 2fr;
          margin-bottom: 0;
        }
      }
      #group-text {
        > div {
          padding: 2rem;
          margin-bottom: 0;
        }
      }
    }

    #download_report_v2_cta p {
      margin-bottom: 0;
    }
  }
  .investor-gudes-cta {
    margin: 2rem auto;
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
  }
  div.top-div-investor-guides {
    .wp-block-group__inner-container {
      box-shadow: none !important;
      padding: 0;
    }
  }
  div.wp-block-columns {
    div {
      padding: 1rem;
      // padding-left: 0;
      // padding-right: 0;
    }
    img {
      max-height: 350px;
      // min-width: 200px;
      border-radius: 0.25rem;
      margin: 0 auto;
    }
    p {
      // font-size: 1.5rem;
      // text-align: left;
      // margin: 0;
      span {
        font-weight: 400;
        color: var(--tertiary);
      }
    }
  }
  #download-report-cta  {
  h2 {
    text-align:center;
  }
  div.wp-block-columns {
      display: grid;
      align-items: flex-start;
      font-size: 1.125rem;
      
      grid-template-columns: 4fr 2fr;
    }
  }
  figure  {
    border-radius: 0.75rem;
  }
  img {
    border-radius: 0.25rem !important;
  }

  #active-rentals  {
    div.wp-block-columns {
        display: grid;
        // align-items: flex-start;
        font-size: 1.125rem;
        
        grid-template-columns: 4fr 2fr;
      }
    }
  #first_cta_investor_guides {
    display: grid;
    font-size: 1.125rem;  
    grid-template-columns: 2fr 2fr;
    text-align: left;
    img {
      max-height: inherit;
      border-radius: 0.50rem !important;
      
    }
  }
  #two-images  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 2fr 2fr;
      }
    }
  #self-manage-cta  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;

        grid-template-columns: 2fr 2fr;
    }
  }
  #free-market-reports-cta {
    margin: 1rem auto;
    max-width: 1200px;
    border-radius: 0.5rem;
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    background: url("${process.env.PUBLIC_URL}/images/markets-bg.webp");
    background-size: cover;
    h2 {
      text-align: center;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    h3 {
      // color: var(--quinary);

    }
    h4 {
      text-align: center;
      margin: 0;
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      color: var(--tertiary);
    }
    div.wp-block-columns {
      display: grid;
      align-items: flex-start;
      font-size: 1.125rem;
      
      grid-template-columns: 2fr 2fr;
    }
  }
  #adr  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        
        grid-template-columns: 2fr 4fr;
    }
  }
  #occupancy-rate  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 4fr 2fr;
    }
  }
  #high-performance-props  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 2fr 4fr;
    }
   
  }
  #annual-revenue  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 2fr 2fr;
    }
  }
  #demand  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 2fr 3fr;
    }
  }
  #other-facts  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 4fr 2fr;
    }
  }
  #str-regulation  {
    div.wp-block-columns {
        display: grid;
        align-items: flex-start;
        font-size: 1.125rem;
        ;
        grid-template-columns: 4fr 2fr;
    }
  }



  #download-report-top-button, #str-calculator-button, 
  #connect-with-depreciation-specialist-button, #contact-us-button-self-manage-cta,
  #connect-with-cost-seg-study-specialist-button {
    padding: 1rem;
    display: block;
    border-radius: 0.25rem;
    margin: 2rem auto;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 0 43px rgba(240,111, 124, 0.15);
    background: var(--quinary);
    color: var(--white);
    // text-decoration: underline;
    text-decoration: none;
      text-transform: uppercase;
    a {
      color: var(--white);
    }
  }
  #connect-with-cost-seg-study-specialist-button {
    margin-top: 3rem;
  }
  #contact-us-button-self-manage-cta {
    background-color: var(--white);
    text-align: center;
    color:var(--tertiary) !important;
  }
  #str-calculator-button {
    margin: 3rem auto !important;
  }

  .excerpt {
    font-style: italic;
  }

  p#investor-guide-button {
    text-align: center;
    a {
      padding: 0.5rem;
      width: 60%;
      border-radius: 0.25rem;
      margin: 0 auto;
      background-color: var(--secondary);
      color: var(--white);
      text-align:center;
      // text-decorations:none;
      font-size: 2rem;
      display:block; 
    } 
  }
 
  .try-the-calculator {
    img {
      max-height: 650px !important;
    }
    p#str-calculator-button {
      text-align: center;
      a {
        padding: 0.5rem;
        width: 100%;
        border-radius: 0.25rem;
        margin: 0 auto;
        background-color: var(--tertiary);
        color: var(--white);
        text-align:center;
        text-decorations:none;
        display:block; 
      } 
    }
  }
  #investor-guides-button {
    text-align: center;
      a {
        padding: 0.5rem;
        width: 100%;
        border-radius: 0.25rem;
        margin: 0 auto;
        background-color: var(--tertiary);
        color: var(--white);
        text-align:center;
        text-decorations:none;
        display:block; 
      } 
  }
  
  p.footnote {
    font-size: 1.2rem !important;
    font-style: italic;
  }
  figure {
    margin: 0;
  }
  li {
    font-size: 1.3rem !important;
    // font-weight: 200;
  }
  img {
    height: auto;
    width: 100%;
  }
 
  div.wp-block-group__inner-container {
    // box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
    padding: 3rem 2rem;
    border-radius: 0.75rem;
    margin: 0 auto 2rem;
  }
  #ctas {
    // > div {
    position:relative;
    display:flex;
    justify-content: center;
    margin-top: 1.5rem;
    #invest-now, #download-report {
    max-width: 50%;
    padding: 1rem;
    position: relative; 
    h2 {
      text-align:center;
    }
    }
  }
  #invest-now {
    background var(--quaternary);
    color: var(--white);
    border-radius: 0.25rem;
    box-shadow: none;
    h2 {
      color: var(--white);
    }
    p {
      text-align:center;
      font-size: 1.5rem;
    }
    a {
      background: var(--white);
      padding: 1rem;
      display: block;
      margin: 1rem auto;
      text-align: center;
      color: var(--tertiary);
      text-decoration: none;
      width: 25%;
      min-width: 200px;
      border-radius: 0.25rem;
    }
  }
  #str-calculator-container {
    background:var(--primary);
    color: var(--white);
    border-radius: 0.25rem;
  }
  p#download-market-report-button a {
    background: var(--tertiary);
  }
  #download-report {
    // background var(--quinary);
    // color: var(--white);
    border-radius: 0.25rem;
    p {
      text-align:center;
      font-size: 1.5rem;
    }
    a {
      background: var(--secondary);
      padding: 1rem;
      display: block;
      margin: 1rem auto;
      text-align: center;
      color: var(--white);
      text-decoration: none;
      width: 80%;
      min-width: 200px;
      border-radius: 0.25rem;
    }
  }

  #str-calculator {
    .wp-block-columns {
      display: flex:
      ;
    }

  }
  
  .demand-analysis {
    .wp-block-columns {
      // display: block;
      justify-content: space-around;
      // div {
      //   max-width: 100%;
      //   display: block;
      // }
      img {
        max-height: 400px;
      }
    }
  }
  #download-report-post-button {
    a {
      display: block;
      padding: 1rem;
      margin: 0 auto;
      background: var(--quinary);
      color: var(--white);
      max-width: 300px;
    }
  }
  #grid-image {
    img {
      border: 1px solid var(--tertiary);
      box-shadow: 8px 8px 0px 0px var(--quinary);
      padding: 1rem;
      // grid-row-start:1;
    }
    
  }

  @media (max-width: 800px) {
    padding: 1rem;
    display: block;
    figcaption {
      font-size: 0.9rem;
    }
    .main-content {
      max-width: 100%;
    }

    .recommended-posts {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }
    #intro-split-view, .split-view {
      div.wp-block-column:nth-child(2) {
        grid-row-start:1;
      }
      img {
        padding: 1rem;
      }
    }
    h1 {
      text-align: center;
    }
    .split-view {
      > div {
        padding: 1rem !important;
      }
      display: block;
      margin: 2rem 0;
      img {
        padding: 1rem;
      }
    }
    #content-group {
      
      > div.wp-block-group__inner-container {
        display: block;
        // grid-template-columns: 3fr 2fr;
        // align-items: center;
      }
      
  
    }
    
    #free-market-reports-cta {
      padding: 0.5rem;
      width: 105%;
      margin: 0 -0.5rem;
      border-radius: 0.5rem;
      padding: 1rem !important;
      h2 {
        font-size: 1.5rem;
      }
      h3 {
        padding: 1rem 0;
        font-size: 1.2rem;
      }
      div.wp-block-columns {
        display: grid;
        padding: 0.5rem;
        #grid-image {
          border: 1px solid var(--tertiary);
          box-shadow: 8px 8px 0px 0px var(--quinary);
          grid-row-start:1;
          h3 {
            display:none;
          }
          img {
            box-shadow:none;
            border: none;
          }
        }
      }
      div.wp-block-group__inner-container {
        padding: 1rem 0;
      }
      #download_report_v2_cta {
        div.wp-block-group__inner-container {
          display: block;
          #email_for_download_report, #name_for_download_report {
            margin-right: 0;
            margin-bottom: 1rem;
            border-radius:0.25rem; 
            width: 100%;
            -webkit-appearance: none;
          }
        }
      }
    }
    #three-images  {
      .wp-block-columns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
      }
    }
    #adr, #high-performance-props, #demand {
      div.wp-block-column:last-of-type {
        grid-row-start: 1
      }
    }
    .size-full img {
      height: auto;
      width: 100%;
      margin: 0 auto;
      display: block;
      padding: 0.5rem;
    }
    div.wp-block-group__inner-container {
      padding: 0;
    }
    div.wp-block-columns {
      grid-gap: 1rem !important;
      grid-template-columns: 1fr !important;
      grid-template-rows: auto auto !important;
      justify-items: center !important;
      
      div {
        padding-left: 0;
        padding-right: 0;
      }
      figure {
        grid-row-start: 1
      }
    }
    iframe {
      width: 300px;
      height: 242px;
      display:none;
    }
    #realtor-cta {
      padding:1.5rem;
      figure#logo {
        img {
          width: 50px;
          
        }
      }
      h2 {
        font-size: 1.7rem !important;
        line-height: 1.9rem !important;
        text-align: center !important;
      }
      h2:first-of-type {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;
      }
      h2:last-of-type {
        margin-top: 0 !important;
        margin-bottom: 2rem !important;
      }
      div.wp-block-group__inner-container {
        margin: 0;
        padding: 1rem;
        box-shadow: none;
        div.wp-block-columns {
          display: block;
          // grid-template-columns: 4fr 2fr;
        }
      }
      p#realtor-cta-headline {
        display: block;
        font-size: 1.3rem;
        // margin: 1rem 0rem;
        text-align:center;
        display:none;
      }
      
      #realtor-cta-buttons {
        p {
          margin-bottom: 1rem !important;
          font-size: 1rem !important;
          text-align: center;
        }
        padding: 0;
        div.wp-block-group__inner-container {
          display: block;
        }
    }
   
    
    
    padding: 1rem;
    #cta {
      div.wp-block-group__inner-container {
        padding:0rem;
      }
      > div {
        box-sizing: content-box;
      }
      padding: 1rem;
      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem !important;
      }
      p {
        
        width: 100%;
        
      }
    }
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.8rem;
      box-shadow: none;
    }
    h3 {
      font-size: 1.6rem;
    }
    
    
    div.top-div-investor-guides {
      padding: 0 !important;
    }
    #ctas {
      display:block;
    }
    #str-calculator-button {
      margin: 6rem auto !important;
    }
    #str-calculator-subtitle {
      margin-top: -6rem !important;
      margin-bottom: 3rem;
    }
    #annual-revenue, #active-rentals, #adr, #occupancy-rate, #two-images,
    #high-performance-props, #demand, #str-regulaton, #other-facts, 
    #free-market-reports-cta, #download-report-cta, #self-manage-cta {
      padding: 0rem;
      box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;
      margin-bottom: 3rem;
      
    }
    #adr, #high-performance-props, #demand {
      div:first-child {
        grid-row-start: 2;
      }
    }
    
    #free-market-reports-cta, #download-report-cta {
      
      div.wp-block-group__inner-container {
        padding: 1rem;
        margin: 0px auto;
        h2 {
          margin-top: 0;
          font-size: 1.5rem;
        }
        p {
          text-align: center;
          margin-bottom: 1rem;
        }
      }
      
      padding: 0.75rem !important;
      h4 {
        margin: 0.5rem;
      }
    }
    
    #invest-now, #download-report {
      max-width: inherit !important;
      padding: 1rem;
      display: block;
      // display: flex;
      
      // min-width: 50%;
      // justify-content: flex-end;
      position: relative; 
      a {
        width: 100%;
      }
    }
    div.wp-block-group__inner-container {
      // margin: 0 auto 2rem;
      padding: 0rem;
      box-shadow: none;
      h2 {
        font-size: 2rem;
        margin-bottom:  1rem;
      }
    }
    p#investor-guide-button {
      text-align: center;
      a {
        width: 100%;
      } 
    }
    p.download-report {
      text-align: center;
    }
    p, li {
      font-size: 1.3rem !important;
    }

    .wp-block-columns {
      display: block;
      div {
        max-width: 100%;
      }
      p {
        font-size: 1.3rem !important;
      }
      img {
        max-height: initial;
        min-width: initial;
      }
    }

    .supply-analysis, .demand-analysis {
      .wp-block-columns {
        display: block;
        // justify-content: space-around;
        div {
          max-width: 100%;
          display: block;
        }
      }
    }
  }  
`;

export default Post;
