import React from "react";
import { Helmet } from "react-helmet";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Helmet>
        {/* Organization Schema.org Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Chalet",
              "url": "https://www.getchalet.com",
              "logo": "https://www.getchalet.com/images/logo2024.png",
            "sameAs": [
                "https://www.facebook.com/getchalet",
                "https://www.twitter.com/chalet"
              ]
            }
          `}
        </script>
      </Helmet>

      {/* Other layout elements (header, footer, etc.) */}
      {children}
    </>
  );
};

export default Layout;
