import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

const AccordionContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isOpen: false,
  setIsOpen: () => undefined
});

const StyledAccordionTrigger = styled.div`
  display: contents;
  cursor: pointer;
`;

function AccordionTrigger({ children }: { children: React.ReactNode }) {
  const { setIsOpen } = React.useContext(AccordionContext);

  return (
    <StyledAccordionTrigger
      onClick={() => {
        console.log("trigger");
        setIsOpen((prevIsOpen) => !prevIsOpen);
      }}
    >
      {children}
    </StyledAccordionTrigger>
  );
}

const StyledAccordionContent = styled.div`
  display: contents;
`;

function AccordionContent({ children }: { children: React.ReactNode }) {
  const { isOpen } = React.useContext(AccordionContext);

  if (!isOpen) {
    return null;
  }

  return <StyledAccordionContent>{children}</StyledAccordionContent>;
}

const StyledAccordionIndicator = styled.div<{ $isOpen?: boolean }>`
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  padding: 0 0.25rem;
  ${(props) =>
    props.$isOpen
      ? css``
      : css`
          transform: rotate(-90deg);
        `}
`;

function AccordionIndicator({ children }: { children: React.ReactNode }) {
  const { isOpen } = React.useContext(AccordionContext);

  return (
    <StyledAccordionIndicator $isOpen={isOpen}>
      {children}
    </StyledAccordionIndicator>
  );
}

function Accordion({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <AccordionContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </AccordionContext.Provider>
  );
}

Accordion.Content = AccordionContent;
Accordion.Indicator = AccordionIndicator;
Accordion.Trigger = AccordionTrigger;

export default Accordion;
