import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PopupButton } from "@typeform/embed-react";
import Section from "../components/Section";
import Text from "../components/Text";
import Select, { OptionProp } from "../components/Select";
import Header from "../components/Header";
import Post from "../components/Post";
import { AppContext } from "../components/AppContext";
import Image from "../components/Image";
import SplitView from "../components/SplitView";
import useCookies from "../hooks/useCookies";
import SkeletonLanding from "../components/SkeletonLanding";
import "../styles/BestAirbnbs.scss";

import { Featured, FeaturedCenter } from "../components/Featured";

const BestAirbnbsLocationsDropdown = styled(Select)`
  display: flex;
  flex-direction: column;
  max-height: 260px;
  margin-top: inherit;
  background-color: var(--quinary);
  color: var(--white);
  overflow-y: auto;
`;

const StyledSectionBestAirbnbs = styled(Section)`
  @media (max-width: 800px) {
    svg {
      top: 0.5rem;
    }
  }
`;

const defaultForm = {
  fullName: "",
  // phone: "",
  email: "",
  // interestedIn: "",
  consent: true
};

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const BestAirbnbs = () => {
  const history = useHistory();
  const { slug } = useParams<any>();
  const [isContactUs, setContactUs] = useState(false);
  const [activePost, setActivePost] = useState<any>();
  const [, setThankYou] = useState(false);
  const [categories, setCategories] = useState<OptionProp[]>();
  const { mobileSize, user, prerenderHeight, setPrerenderHeight } =
    useContext(AppContext);
  setPrerenderHeight(mobileSize ? "2000px" : "1000px");
  const { getAllCookies } = useCookies();
  const isOldMarkets = [
    "broward-county-fl-investor-guide-airbnb-rentals",
    "atlanta-ga",
    "miami-beach-fl",
    "dallas-tx",
    "memphis-tn-investor-guide-gross-yields-from-24",
    "palm-beach-fl",
    "philadelphia-pa",
    "palm-springs-ca",
    "phoenix-scottsdale-az",
    "encinitas-carlsbad-oceanside-ca",
    "summit-county-co"
  ].includes(slug);
  // eslint-disable-next-line
  console.log("is Old markets?", isOldMarkets);
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form] = useState(defaultForm);
  const { fullName, email, consent } = form;

  const [activeCategory, setActiveCategory] = useState<
    OptionProp & { id: number }
  >();

  function getMarketFromSlug() {
    // let marketFromSlug = ''
    let marketFromSlug = slug ? toTitleCase(slug.replace(/-/g, " ")) : "";
    // eslint-disable-next-line no-console
    console.log("market from slug", marketFromSlug);
    if (
      [
        "fort-myers-fl",
        "lake-havasu-city-az",
        "panama-city-beach-fl",
        "pompano-beach-fl",
        "gulf-shores-al",
        "san-antonio-tx",
        "west-palm-beach-fl"
      ].includes(slug)
    ) {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
    } else if (slug === "broward-county-fl-investor-guide-airbnb-rentals") {
      marketFromSlug = "Fort Lauderdale, FL";
    } else if (slug === "memphis-tn-investor-guide-gross-yields-from-24") {
      marketFromSlug = "Memphis, TN";
    } else {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
    }
    return marketFromSlug;
  }
  function standardizeSlug() {
    // let marketFromSlug = ''
    // let marketFromSlug = slug ? toTitleCase(slug.replace("-", " ")) : "";
    let marketFromSlug = slug;
    if (["memphis-tn-investor-guide-gross-yields-from-24"].includes(slug)) {
      marketFromSlug = "memphis-tn";
    } else if (slug === "broward-county-fl-investor-guide-airbnb-rentals") {
      marketFromSlug = "fort-lauderdale-fl";
    } else {
      return marketFromSlug;
    }
    return marketFromSlug;
  }
  function getSearchPlaceholder() {
    let label: any = window.location.pathname.split("/");
    label = label[label.length - 1];
    if (label === "best-airbnbs") {
      return "Search Locations or Categories";
    }
    return getMarketFromSlug();
  }

  useEffect(() => {
    const allCategories: any[] = [];
    const perPage = 100; // Adjust based on what the API supports. Usually, it's 100.

    const fetchCategories = async (page: number) => {
      const response = await fetch(
        `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/categories?per_page=${perPage}&page=${page}`
      );

      // Check for the successful response
      if (!response.ok) {
        throw new Error("Server responded with an error");
      }

      // Read the total number of pages from the headers
      const totalPages = response.headers.get("X-WP-TotalPages");

      // Parse the JSON response
      const body = await response.json();

      // Filter and map the categories as before
      const newCategories = body
        .filter(
          (category: any) =>
            category.link.includes("best-airbnbs") &&
            category.slug !== "best-airbnbs"
        )
        .map((category: any) => ({
          id: category.id,
          label: category.name,
          value: category.slug
        }));

      allCategories.push(...newCategories);

      return totalPages ? parseInt(totalPages, 10) : 1;
    };

    const fetchAllCategories = async () => {
      try {
        // Start with the first page
        const totalPages = await fetchCategories(1);

        // If there are more pages, fetch those as well
        if (totalPages > 1) {
          // Create an array of promises for all the pages we want to fetch
          const fetchPromises = [];
          for (let page = 2; page <= totalPages; page++) {
            fetchPromises.push(fetchCategories(page)); // no 'await' here
          }

          // Now we wait for all the pages to be fetched concurrently
          await Promise.all(fetchPromises);
        }

        // After all pages have been fetched, update the state
        setCategories(allCategories);
      } catch (error) {
        // Handle the error here
        // eslint-disable-next-line no-console
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchAllCategories();
  }, []);

  useEffect(() => {
    // console.log("slug", slug);
    if (slug) {
      fetch(
        `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/best-airbnbs?slug=${slug}`
      )
        .then((response) => response.json())
        .then((body) => {
          setActivePost(body[0]);
        });
    } else {
      setActivePost(undefined);
      setPrerenderHeight("auto");
    }
  }, [slug]);

  useEffect(() => {
    if (activeCategory) {
      fetch(
        `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/best-airbnbs?categories=${activeCategory?.id}`
      )
        .then((response) => response.json())
        .then((body) => {
          if (body[0]) {
            setActivePost(body[0]);
            // eslint-disable-next-line
            console.log("Url slug", body[0].slug);
            history.push(`/best-airbnbs/${body[0].slug}`);
          }
        });
    }
  }, [activeCategory]);

  useEffect(() => {
    activePost?.categories.forEach((cat: any) => {
      const tempCat = (categories as any)?.find((c: any) => c.id === cat);
      if (tempCat) {
        setActiveCategory(tempCat);
      }
    });
  }, [activePost, user]);

  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
      console.log("name", fullName);
      // eslint-disable-next-line
      console.log("Consent", consent);
      const emailDownloadMarketReportEl = document.getElementById(
        "email_for_download_report"
      ) as HTMLInputElement;
      const nameDownloadMarketReportEl = document.getElementById(
        "name_for_download_report"
      ) as HTMLInputElement;

      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name:
              fullName ||
              (nameDownloadMarketReportEl
                ? nameDownloadMarketReportEl.value
                : ""),
            email:
              email ||
              (emailDownloadMarketReportEl
                ? emailDownloadMarketReportEl.value
                : ""),
            downloadReport: true,
            market: getMarketFromSlug(),
            consent,
            ...cookies,
            sourcePage: "best-airbnbs"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );

        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
        console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);

  const onSelect = (e: any) => {
    setActiveCategory(e.value);
  };
  return (
    <div
      className="best-airbnbs_main"
      style={{
        paddingTop: mobileSize ? "120px" : "120px",
        background: mobileSize ? "none" : "none"
      }}
    >
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/best-airbnbs" />
        <title>Review of best Airbnbs in the United States</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Chalet provides analysis of short-term rental real estate
            markets and national economic trends. Ultimate investor guides
            contain everything from regulations, taxgross yields, median revenue,
            occupancy rates, guest origin, top property management firms, and much more."
        />
        <meta
          property="og:title"
          content="Review of best Airbnbs in the United States"
        />
        <meta
          property="og:description"
          content="Chalet provides analysis of short-term rental real estate
            markets and national economic trends. Ultimate investor guides
            contain everything from regulations, taxgross yields, median revenue,
            occupancy rates, guest origin, top property management firms, and much more."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/best-airbnbs"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <StyledSectionBestAirbnbs
        className="search-top-airbnbs"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: mobileSize ? "0rem" : "1rem",
          overflow: "inherit",
          marginBottom: !mobileSize ? "-8rem" : "initial",
          boxShadow: "none",
          borderRadius: "0.75rem 0.75rem 0 0rem",
          background: "none"
        }}
      >
        <SplitView
          split="1:1"
          style={{
            padding: mobileSize ? "1rem" : "2rem 2rem 0",
            // marginBottom: 0,
            paddingTop: "1rem",
            alignItems: "flex-start",
            // gridTemplateColumns: "2fr 3fr",
            // backgroundColor: "rgba(255,255,255, 0.8)",
            maxWidth: mobileSize ? "100vw" : "85vw",
            margin: "1rem auto 0"
          }}
        >
          <div id="location-container" style={{ padding: 0 }}>
            <Text
              weight={300}
              block
              style={{
                fontSize: mobileSize ? "1.7em" : "2.4rem",
                lineHeight: mobileSize ? "2rem" : "2.5rem",
                paddingBottom: mobileSize ? "1rem" : "0.5rem",
                fontWeight: 400,
                color: "var(--tertiary)"
              }}
            >
              Uncover the Best Airbnbs Across the U.S.!
            </Text>
            <Text
              weight={400}
              block
              style={{
                fontSize: mobileSize ? "1.2em" : "1.2rem",
                lineHeight: mobileSize ? "2rem" : "2.5rem",
                paddingBottom: "1rem",
                color: "var(--quinary)"
              }}
            >
              Explore Exceptional Vacation Rentals with Our Expertly Curated
              Selections
            </Text>
            <BestAirbnbsLocationsDropdown
              style={{
                marginTop: mobileSize ? "1rem" : "initial",
                padding: "1rem"
              }}
              isSearchable
              options={categories}
              placeholder={getSearchPlaceholder()}
              onChange={onSelect}
              data-id="best-airbnbs_dropdown"
            />
          </div>

          <div style={{ padding: "0px" }}>
            <Image
              className={`search-image + ${activePost ? "_bg" : ""}`}
              src={`${process.env.PUBLIC_URL}/images/blog_header.webp`}
              alt="Review of best Airbnbs in the United States"
              style={{
                margin: "0 auto",
                padding: "0rem",
                maxWidth: "100%"
              }}
              $mobileSize={mobileSize}
            />
          </div>
        </SplitView>
        {/* )} */}
      </StyledSectionBestAirbnbs>
      {!activePost && (
        <>
          <div
            style={{
              backgroundColor: "var(--white)"
            }}
          >
            <Post id="no-market">
              <SkeletonLanding
                id="skeleton"
                width="100%"
                height={prerenderHeight}
              >
                <div id="no-market_intro">
                  Welcome to your ultimate gateway for finding the most
                  remarkable Airbnb stays in the United States! Whether
                  you&apos;re seeking a cozy cabin in the woods, a chic city
                  apartment, or a luxurious beachfront villa, our initiative is
                  dedicated to bringing you the finest selections that Airbnb
                  has to offer.
                </div>
                <Featured title="Featured Markets">
                  <FeaturedCenter
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2023/11/charleston-sc.webp"
                    title="Best Airbnbs in Charleston, SC"
                    text="In this update, we have some changes to share with you, as well as valuable insights into the trends shaping the short-term rental market."
                    href="/best-airbnbs/charleston-sc"
                    // cta="READ MORE"
                    right
                  />
                  <FeaturedCenter
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2023/11/sedona-az.webp"
                    title="Best Airbnbs in Sedona, AZ"
                    text="In this update, we have some changes to share with you, as well as valuable insights into the trends shaping the short-term rental market."
                    href="/best-airbnbs/sedona-az"
                    // cta="READ MORE"
                  />
                  <FeaturedCenter
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2023/11/d9a53a2f-7057-4341-8bd1-0a3899c40438.webp"
                    title="Best Airbnbs in Joshua Tree, CA"
                    text="In this update, we have some changes to share with you, as well as valuable insights into the trends shaping the short-term rental market."
                    href="/best-airbnbs/joshua-tree-ca"
                    // cta="READ MORE"
                  />
                  <FeaturedCenter
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2023/11/DALL·E-2023-11-13-14.40.21-A-scenic-view-of-San-Antonio-Texas-showcasing-the-iconic-landmarks-such-as-the-Alamo-the-Tower-of-the-Americas-and-the-San-Antonio-River-Walk.-The.png"
                    title="Best Airbnbs in San Antonio, TX"
                    text="In this update, we have some changes to share with you, as well as valuable insights into the trends shaping the short-term rental market."
                    href="/best-airbnbs/san-antonio-tx"
                    // cta="READ MORE"
                    right
                  />
                </Featured>
              </SkeletonLanding>
            </Post>
          </div>
        </>
      )}
      {activePost && (
        <>
          <div
            style={{
              backgroundColor: "white"
            }}
          >
            <Helmet>
              {(activePost as any)?.head_tags.map(
                ({ content, tag, attributes }: any, idx: number) => {
                  if (tag === "title") {
                    return <title key={tag}>{content}</title>;
                  }
                  if (tag === "meta") {
                    const { name, content: metaContent, property } = attributes;
                    const replacedContent = metaContent
                      .replace("http", "https")
                      .replace("44.231.197.151", window.location.host);
                    if (name) {
                      return (
                        <meta
                          key={name}
                          name={name}
                          content={replacedContent}
                        />
                      );
                    }
                    if (property) {
                      return (
                        <meta
                          key={property}
                          property={property}
                          content={replacedContent}
                        />
                      );
                    }
                  }
                  if (tag === "link") {
                    const { href, ...otherAttributes } = attributes;
                    let replacedHref = href;
                    const key = `link-${idx}`;
                    if (
                      href.includes("44.231.197.151") ||
                      href.includes("dgbqmpxvjgsxl.cloudfront.net")
                    ) {
                      replacedHref = replacedHref
                        .replace("http", "https")
                        .replace("44.231.197.151", window.location.host)
                        .replace(
                          "dgbqmpxvjgsxl.cloudfront.net",
                          window.location.host
                        );
                    }
                    if (href.includes("investor_guides")) {
                      replacedHref = replacedHref.replace(
                        "investor_guides",
                        "investor-guides"
                      );
                    }
                    return (
                      <link
                        key={key}
                        href={replacedHref}
                        {...otherAttributes}
                      />
                    );
                  }
                  return null;
                }
              )}
            </Helmet>
            <Post>
              {(activePost as any)?.title.rendered
                .split(":")
                .map((t: string, idx: number) => (
                  <Header
                    key={t}
                    subtitle={idx === 1}
                    // style={{ fontSize: mobileSize ? "2rem" : "3rem" }}
                  >
                    {t}
                  </Header>
                ))}
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: (activePost as any)?.content?.rendered
                }}
              />
              <div
                id="ctas"
                style={{
                  boxShadow: mobileSize
                    ? "none"
                    : "rgb(67 73 94 / 27%) 0px 0px 43px",
                  background: `url(${process.env.PUBLIC_URL}/images/banner_just_clouds_purple.png)`,
                  borderRadius: "0.5rem",
                  backgroundSize: "cover",
                  padding: mobileSize ? "0rem" : "1rem"
                  // padding: mobileSize ? 0 : "2rem"
                }}
              >
                <Section
                  style={{
                    boxShadow: mobileSize
                      ? "0 0 43px rgb(0 0 0 / 10%)"
                      : "none",
                    background: "none",
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100wv" : "100wv",
                    padding: mobileSize ? "2rem" : "0rem"
                  }}
                >
                  <Header
                    size={1}
                    // font={Font.Pacifico}
                    style={{
                      color: "var(--tertiary)",
                      textAlign: mobileSize ? "center" : "left",
                      display: mobileSize ? "none" : "block"
                    }}
                  >
                    <img
                      // src={`${process.env.PUBLIC_URL}/logo.webp`}
                      src={`${process.env.PUBLIC_URL}/images/logo2024.png`}
                      alt="Chalet"
                      style={{
                        // marginTop: mobileSize ? "0.5rem" : "0rem",
                        width: mobileSize ? "110px" : "110px",
                        marginBottom: mobileSize ? "0rem" : "-0.1em"
                      }}
                      loading="lazy"
                    />
                  </Header>
                  <div className="first">
                    {/* <Header size={2} style={{ color: "var(--tertiary)" }}>
            Airbnb Rental Investing for{" "}
            <Text style={{ fontSize: "2.0rem", fontWeight: 400 }}>
              {" "}
              The Independent & Savvy
            </Text>{" "}
          </Header> */}
                  </div>
                  <SplitView split="1:1" style={{ alignItems: "center" }}>
                    <div style={{ gridRowStart: mobileSize ? 2 : "initial" }}>
                      <div
                        style={{ marginTop: mobileSize ? "2rem" : "initial" }}
                        className="second"
                      >
                        <Text block style={{ textAlign: "left" }}>
                          Chalet helps you find{" "}
                          <Text>
                            the{" "}
                            <Text
                              style={{
                                // fontSize: mobileSize ? "2rem" : "1.4rem",
                                fontWeight: 500
                                // color: "var(--quinary)"
                              }}
                            >
                              right market
                            </Text>
                          </Text>
                          ,{" "}
                          <Text>
                            the{" "}
                            <Text
                              style={{
                                // fontSize: mobileSize ? "2rem" : "1.6rem",
                                fontWeight: 500
                              }}
                            >
                              right realtor
                            </Text>
                          </Text>
                          &nbsp;and{" "}
                          <Text>
                            the{" "}
                            <Text
                              style={{
                                // fontSize: mobileSize ? "2rem" : "1.6rem",
                                fontWeight: 500
                              }}
                            >
                              right Airbnb rental investment
                            </Text>
                          </Text>
                        </Text>
                      </div>
                      <div
                        className="third"
                        style={{
                          alignItems: "center",
                          display: mobileSize ? "block" : "flex",
                          fontSize: "1rem",
                          paddingTop: ".75rem",
                          padding: mobileSize ? "0" : "0rem",
                          paddingLeft: "0rem"
                        }}
                      >
                        {" "}
                        <PopupButton
                          id="lMhk4Fej"
                          // open="load"
                          // data-tf-lazy="false"
                          shareGaInstance="true"
                          hidden={{
                            market_of_interest:
                              getMarketFromSlug() || "Not Specified",
                            source_page: slug
                          }}
                          data-id="talk-to-str-expert"
                          style={{
                            // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                            backgroundColor: mobileSize
                              ? "var(--tertiary)"
                              : "var(--tertiary)",
                            textTransform: "uppercase",
                            color: "var(--white)",
                            padding: "1rem",
                            border: 0,
                            width: mobileSize ? "100%" : "initial",
                            textDecoration: "none",
                            display: mobileSize ? "block" : "initial",
                            fontSize: "1rem",
                            cursor: "pointer",
                            textAlign: "center",
                            boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                            borderRadius: "0.25rem",
                            // display: mobileSize ? "block" : "initial",
                            margin: mobileSize ? "1rem auto" : "1rem"
                          }}
                          className="talk-to-str-expert_button_top"
                        >
                          Talk to a STR Expert
                        </PopupButton>
                        <a
                          style={{
                            // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                            backgroundColor: "var(--quinary)",
                            textTransform: "uppercase",
                            color: "var(--white)",
                            padding: "1rem",
                            width: mobileSize ? "100%" : "initial",
                            textDecoration: "none",
                            fontSize: "1rem",
                            cursor: "pointer",
                            display: mobileSize ? "block" : "initial",
                            textAlign: "center",
                            boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                            borderRadius: "0.25rem",
                            // display: mobileSize ? "block" : "initial",
                            margin: mobileSize ? "1rem auto" : "1rem"
                          }}
                          data-id="learn-more_button_bottom"
                          href={`/real-estate-agents/${standardizeSlug()}`}
                          title="/real-estate-agents"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                    <Image
                      src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
                      alt="Real Estate Agents with Airbnb Rental Expertise"
                      style={{
                        display: mobileSize ? "block" : "block",
                        margin: "0 auto",
                        gridRowStart: mobileSize ? 1 : "initial",
                        padding: "0rem",
                        maxWidth: mobileSize ? "200px" : "250px"
                      }}
                      $mobileSize={mobileSize}
                    />
                  </SplitView>
                </Section>
              </div>

              <div>
                <h6>
                  *This document is for information and illustrative purposes
                  only. It is not intended to provide “investment advice” or a
                  “recommendation” regarding a course of action. The discussion
                  is general in nature and has not taken into account your
                  personal financial position or objectives. You should consult
                  a licensed financial advisor or other professional to discuss
                  your specific situation.
                </h6>
                <h6>
                  Estimated rental income figures and home sale prices are based
                  on historical averages; for any specific property, the actual
                  revenue, purchase price, and cap rate may differ materially
                  from estimated amounts and depend on a wide range of factors
                  outside Chalet&apos;s knowledge or control, such as the
                  property&apos;s condition, layout, and furnishings; expenses
                  associated with the acquisition and ownership of the property
                  (such as property taxes, utility fees, HOA fees, insurance
                  fees, and mortgage-related expenses); rental calendar
                  availability; existing and future regulations; current and
                  future economic, social, and political trends and conditions;
                  and weather and environmental factors. These estimates do not
                  take into account the potential impact of state income taxes.
                </h6>
                <h6>
                  Many locations restrict or impose conditions on the use of
                  properties as vacation rentals, or restrict vacation rentals
                  to certain defined areas. Before purchasing a particular
                  property as a potential vacation rental a buyer should confirm
                  that the property can legally be used for this purpose.
                </h6>
                <h6>
                  Chalet makes no representations or warranties, express or
                  implied, about the accuracy of this document. Furthermore,
                  Chalet has no obligation to update, modify or amend this
                  document or to otherwise notify users in the event that any
                  opinion, assumption, forecast or estimate set forth herein
                  changes or subsequently becomes inaccurate. Therefore, you
                  should not place undue reliance on statements in this
                  document.
                </h6>
              </div>
            </Post>
          </div>
        </>
        // </Section>
      )}
    </div>
  );
};

export default BestAirbnbs;
