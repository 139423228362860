import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Field from "../components/Field";
import Button from "../components/Button";
import useCookies from "../hooks/useCookies";
import Flex from "../components/Flex";
import Image from "../components/Image";
import Group from "../components/Group";
import { Color } from "../types";
import SplitView from "../components/SplitView";
import Header from "../components/Header";
import SelectForCalculator, {
  OptionProp
} from "../components/SelectForCalculator";

const defaultForm = {
  name: "",
  email: "",
  propertyType: "",
  purchaseDate: "",
  buildingCostBasis: "",
  currentTaxYear: "",
  bonusdepreciationPercentage: "",
  ROIFactor: "",
  federalTaxRate: ""
};

const propertyTypes = [
  {
    label: "Multifamily Residential (2-3 units)",
    value: "Multifamily Residential (2-3 units)"
  },
  {
    label: "Multifamily Residential (4-9 units)",
    value: "Multifamily Residential (4-9 units)"
  },
  {
    label: "Multifamily Residential (10+ units)",
    value: "Multifamily Residential (10+ units)"
  },
  { label: "Short Term Rental", value: "Short Term Rental" },
  { label: "Single-Family Residential", value: "Single-Family Residential" },
  { label: "Other", value: "Other" }
];

const bonusdepreciationPercentages = [
  { label: "100%", value: "100%" },
  { label: "80%", value: "80%" },
  { label: "60%", value: "60%" },
  { label: "40%", value: "40%" },
  { label: "20%", value: "20%" },
  { label: "0%", value: "0%" }
];

const currentTaxYears = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" }
];

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  // height: 100vh;
  width: 100vw;
  padding: 5rem 7.5vw 0;
  display: block;
  // width: 50%;
  // justify-content: center;
  // align-items: start;
  // line-height: 1;
  // padding: 5rem 0;
  background: url(./images/mountain_cabin.webp);
  background-repeat: no-repeat;
  background-position-y: 15rem;
  // background-position-x: 100%;
  background-size: cover;

  h1 {
    font-weight: 300;
    font-size: 2.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }

  .first ${Text} {
    white-space: nowrap;
    font-size: 2.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
    display: block;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    align-items: flex-start;
    margin-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: none;
    h3 {
      font-size: 1.8rem;
      ${Text} {
        font-size: 1.8rem;
      }
    }
    .second {
      // display: none;

      h3 {
        font-size: 1rem;
      }
      ${Text} {
        font-size: 1rem !important;
      }
    }
    .first {
      h1 {
        margin: 0;
        text-align: center;
        font-size: 2.2rem;
        line-height: 2.2rem;
      }
      ${Text} {
        white-space: initial;
        text-align: center;
        // font-size: 3.5rem;
        font-size: 2.2rem;
        padding-bottom: 0;
        line-height: 2.2rem;
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;
const ConsentLabel = () => {
  return (
    <div
      style={{
        fontWeight: 300,
        fontSize: "0.7rem",
        marginTop: "-0.9rem",
        marginBottom: "2rem"
      }}
    >
      By submitting this form, you agree to receive subsequent emails and
      third-party marketing communications from Chalet pursuant to our{" "}
      <Link
        to="/privacy-policy"
        target="_blank"
        style={{
          cursor: "pointer",
          display: "inline-block",
          textDecoration: "underline"
        }}
      >
        Privacy Policy
      </Link>
      , which you may opt out of, or unsubscribe from, at any time.
    </div>
  );
};

const CostSegCalculator = () => {
  const { getAllCookies } = useCookies();

  const { mobileSize } = useContext(AppContext);
  const [isContactUs, setContactUs] = useState(false);
  const bodyRef = useRef<HTMLDivElement>();
  const [thankYou, setThankYou] = useState(false);
  const [errors, setErrors] = useState<any>({
    listingId: false,
    phone: false,
    email: false,
    market: false,
    interestedIn: false,
    notes: false
    // consent: true
  });
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form, setForm] = useState(defaultForm);
  const {
    name,
    email,
    propertyType,
    purchaseDate,
    buildingCostBasis,
    currentTaxYear,
    bonusdepreciationPercentage,
    ROIFactor,
    federalTaxRate
  } = form;

  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
    console.log("name", name);
      // eslint-disable-next-line
    // console.log("Consent", consent);
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            propertyType,
            purchaseDate,
            buildingCostBasis,
            currentTaxYear,
            bonusdepreciationPercentage,
            ROIFactor,
            federalTaxRate,

            consent: true,
            ...cookies,
            sourcePage: "cost-seg-calculator"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        const apiData = await fetch(
          "https://gpvf4ni8qj.execute-api.us-west-2.amazonaws.com/v1/cost-seg-calculator",
          requestOptions
        );

        await apiData.json();
        // console.log("api data", result);
        // setNewTitles(result.newTitles);
        // setNewDescription(result.newDescription);
        // setExplanation(result.explanation);
        // setAudienceScores(result.audienceScores);
        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error generating listing title:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);

  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            // if (formName === "consent") {
            //   newErrors.consent = !consent;
            // } else {
            newErrors[formName] = value === "";
            // }
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log("On change name", n);
    // eslint-disable-next-line
    console.log("On change value", value);
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/cost-seg-calculator"
        />
        <title>
          Maximize Tax Savings with Our Free Cost Segregation Calculator
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Estimate potential tax savings, accelerate depreciation, and increase cash flow for your commercial property with our easy-to-use cost segregation calculator. Get a quick estimate today and connect with our specialists for personalized advice."
        />
        <meta
          property="og:title"
          content="Maximize Tax Savings with Our Free Cost Segregation Calculator"
        />
        <meta
          property="og:description"
          content="Estimate potential tax savings, accelerate depreciation, and increase cash flow for your commercial property with our easy-to-use cost segregation calculator. Get a quick estimate today and connect with our specialists for personalized advice."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.getchalet.com" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div style={{ maxWidth: mobileSize ? "100%" : "100%" }}>
          <div className="first">
            <h1
              style={{
                lineHeight: mobileSize ? "2.6rem" : "3.2rem",
                textAlign: "center",
                fontWeight: 300
              }}
            >
              FREE Cost Segregation Calculator
              {/* <Text
                style={{
                  display: mobileSize ? "initial" : "block",
                  fontWeight: mobileSize ? 300 : 400
                }}
              >
                {" "}
                
              </Text> */}
            </h1>
          </div>
          <div className="second">
            <h3
              style={{
                fontSize: mobileSize ? "1.3rem" : "2rem",
                display: mobileSize ? "none" : "block",
                color: "var(--quinary)",
                fontWeight: 300,
                lineHeight: "2.2rem",
                textAlign: "center"
              }}
            >
              Get an Estimate of Your Potential Tax Benefits
            </h3>
          </div>
          <SplitView
            split="2:3"
            style={{
              // alignItems: "center",
              marginTop: "3rem",
              padding: "2rem",
              background: "var(--white)",
              borderRadius: "0.5rem",
              boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)"
            }}
          >
            <div className="third" style={{ display: "block" }}>
              <Text
                style={{
                  display: "block",
                  fontSize: "1rem",
                  padding: mobileSize ? "1rem" : "1rem"
                }}
              >
                Are you a real estate investor or commercial property owner
                looking to maximize your tax savings and increase your cash
                flow?
              </Text>
              <Text
                style={{
                  display: "block",
                  //   display: mobileSize ? "none" : "initial",
                  //   style={{  }}
                  fontSize: "1rem",
                  padding: mobileSize ? "1rem" : "1rem"
                }}
              >
                Our free cost segregation calculator is here to help you quickly
                estimate the potential benefits of cost segregation for your
                property.
              </Text>

              <Header style={{ display: mobileSize ? "none" : "block" }}>
                {" "}
                Key Features
              </Header>
              <ul
                style={{
                  //   display: mobileSize ? "block" : "block",
                  display: mobileSize ? "none" : "block",
                  padding: "1rem",
                  marginTop: mobileSize ? "initial" : "initial"
                }}
              >
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    // lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Ease of Use:
                  </Text>{" "}
                  Our calculator is designed to be user-friendly, allowing you
                  to input property details with ease.
                  {/* <a href="#aboutchalet">NO COST</a> */}
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Estimate Tax Savings:
                  </Text>{" "}
                  Get a rough estimate of how cost segregation could increase
                  your tax savings.
                  {/* <a href="#aboutchalet">NO COST</a> */}
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>

                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Accelerate Depreciation:
                  </Text>{" "}
                  Discover how cost segregation can help you depreciate assets
                  faster.
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Maximize Bonus Depreciation:
                  </Text>{" "}
                  Learn how to take full advantage of bonus depreciation
                  benefits.
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Boost Cash Flow:
                  </Text>{" "}
                  Explore how cost segregation may lead to increased cash flow
                  for your investments.
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
              </ul>
            </div>
            <div
              style={{
                padding: mobileSize ? "3rem 2rem !important" : "3rem 2rem",
                background: "var(--white)",
                borderRadius: mobileSize ? "initial" : "0.5rem",
                boxShadow: mobileSize ? "none" : "0 0 43px rgba(0, 0, 0, 0.1)"
              }}
            >
              {!thankYou && (
                <>
                  <div
                    style={{
                      width: mobileSize ? "calc(100% - 0rem)" : "100%",
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Header size={2} style={{ color: "var(--tertiary)" }}>
                      Get Your FREE Estimate <Text />
                    </Header>
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Your Name
                    </Text>
                    <Field
                      name="name"
                      placeholder="Type Your Name Here"
                      type="text"
                      value={name}
                      invalid={errors.name}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Your Email
                    </Text>
                    <Field
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      invalid={errors.email}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      ROI Factor (Percentage)
                    </Text>
                    <Field
                      name="ROIFactor"
                      placeholder="Enter Your Return On Investment"
                      type="number"
                      value={ROIFactor}
                      invalid={errors.ROIFactor}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Property Type
                    </Text>
                    <SelectForCalculator
                      name="propertyType"
                      options={propertyTypes}
                      style={{ fontSize: "1rem" }}
                      placeholder="Choose Property Type ..."
                      value={propertyTypes.find(
                        (option) => option.label === propertyType
                      )}
                      invalid={errors.propertyType}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Bonus Depreciation Percentage
                    </Text>
                    <SelectForCalculator
                      name="bonusdepreciationPercentage"
                      options={bonusdepreciationPercentages}
                      style={{ fontSize: "1rem" }}
                      placeholder="Choose Bonus Depreciation Percentage ..."
                      value={bonusdepreciationPercentages.find(
                        (option) => option.label === bonusdepreciationPercentage
                      )}
                      invalid={errors.bonusdepreciationPercentage}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Current Tax Year
                    </Text>
                    <SelectForCalculator
                      name="currentTaxYear"
                      options={currentTaxYears}
                      style={{ fontSize: "1rem" }}
                      placeholder="Choose Current Tax Year ..."
                      value={currentTaxYears.find(
                        (option) => option.label === currentTaxYear
                      )}
                      invalid={errors.currentTaxYear}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Federal Tax Rate (Percentage)
                    </Text>
                    <Field
                      name="federalTaxRate"
                      placeholder="Enter Your Estimated Federal Tax Rate Percentage"
                      type="number"
                      value={federalTaxRate}
                      invalid={errors.federalTaxRate}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Building Cost Basis
                    </Text>
                    <Field
                      name="buildingCostBasis"
                      placeholder="Enter Your Estimated Building Cost"
                      type="number"
                      value={buildingCostBasis}
                      invalid={errors.buildingCostBasis}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Purchase Date
                    </Text>
                    <Field
                      name="purchaseDate"
                      placeholder="Enter The Property Purchase Date"
                      type="date"
                      value={purchaseDate}
                      invalid={errors.purchaseDate}
                      onChange={onChange}
                    />
                    <ConsentLabel />
                    {Object.values(errors).some((value) => value) && (
                      <Text
                        color={Color.danger}
                        style={{
                          display: "block",
                          padding: "0.5rem",
                          textAlign: "center",
                          width: "100%"
                        }}
                      >
                        Fields{" "}
                        {Object.entries(errors)
                          .map(([key, value]) => (value ? key : undefined))
                          .filter((str) => str !== undefined)
                          .join(", ")}{" "}
                        are missing values
                      </Text>
                    )}
                    <Button
                      isLoading={isContactUs}
                      style={{
                        marginBottom: "2rem",
                        width: "100%",
                        background: "var(--tertiary)"
                      }}
                      onClick={() => allowContactUs() && setContactUs(true)}
                      data-id="modal_contact_us_message_us_button"
                    >
                      GET FREE ESTIMATE
                    </Button>
                  </div>
                </>
              )}
              {thankYou && (
                <Flex
                  style={{
                    alignItems: "stretch",
                    flexDirection: "column",
                    padding: mobileSize ? "0.5rem" : "3rem",
                    textAlign: "center",
                    minHeight: "70vh"
                  }}
                >
                  <Text
                    weight={400}
                    color={Color.quaternary}
                    style={{ fontSize: "1.2rem", padding: 0 }}
                  >
                    Thank you for submitting your information!
                  </Text>
                  <Text style={{ fontSize: "1.2rem", padding: 0 }}>
                    Free Cost Segregation Estimate will be sent to your inbox
                  </Text>
                  <Text
                    style={{
                      display: "block",
                      color: "var(--tertiary)",
                      fontSize: "1.5rem",
                      padding: 0,
                      margin: "2rem 0 0"
                    }}
                  >
                    Ready to take the next step in optimizing your tax strategy?
                  </Text>
                  <Text
                    style={{
                      display: "block",
                      color: "var(--tertiary)",
                      fontSize: "1.5rem",
                      padding: 0,
                      margin: "1rem 0 2rem"
                    }}
                  >
                    Our cost segregation specialists are here to assist you.
                    Fill out the contact form, and we&apos;ll be in touch with
                    you shortly to provide expert advice tailored to your unique
                    situation.
                  </Text>
                  <PopupButton
                    id="Q3vyi5Mv"
                    // open="load"
                    // data-tf-lazy="false"
                    shareGaInstance="true"
                    data-id="cost_seg_specialist"
                    style={{
                      // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                      backgroundColor: mobileSize
                        ? "var(--tertiary)"
                        : "var(--tertiary)",
                      textTransform: "uppercase",
                      color: "var(--white)",
                      padding: "1rem",
                      border: 0,
                      width: mobileSize ? "100%" : "initial",
                      textDecoration: "none",
                      display: mobileSize ? "block" : "initial",
                      fontSize: "1rem",
                      cursor: "pointer",
                      textAlign: "center",
                      boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                      borderRadius: "0.25rem",
                      // display: mobileSize ? "block" : "initial",
                      margin: mobileSize ? "1rem auto" : "1rem"
                    }}
                    className="talk-to-str-expert_button_top"
                  >
                    Connect With Cost Seg Specialist
                  </PopupButton>
                </Flex>
              )}
            </div>
          </SplitView>
        </div>
        <div style={{ display: "none", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
    </>
  );
};

export default CostSegCalculator;
