import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Image from "../components/Image";
import Section from "../components/Section";
import Text, { Font } from "../components/Text";
import SplitView from "../components/SplitView";
import { Color } from "../types";
import Button from "../components/Button";
import SectionFooter from "../components/SectionFooter";
import Group from "../components/Group";
import ContactUsModal from "./modals/ContactUsModal";

const ChaletConcierge = () => {
  const { mobileSize } = useContext(AppContext);

  const history = useHistory();

  const handleFindARealtorClick = () => {
    window.location.href = "https://form.typeform.com/to/lMhk4Fej";
  };
  const handleFindPropertyManagerClick = () => {
    window.location.href = "https://form.typeform.com/to/lMhk4Fej";
  };
  const handleFindAirbnbCleanersClick = () => {
    history.push("/turnover-services");
  };
  const handleInvestorGuideClick = () => {
    window.open("/investor-guides/phoenix-scottsdale-az", "_blank");
  };
  const handleAirbnbConsultingClick = () => {
    history.push("/remote-manage-your-airbnbs");
  };
  const handle1031ExchangeClick = () => {
    history.push("/1031-exchange");
  };

  const handleContactUsClick = () => {
    window.location.href = "https://form.typeform.com/to/lMhk4Fej";
  };

  return (
    <div style={{ paddingTop: "208px" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/phoenix-scottsdale-az"
        />
        <title>
          Phoenix, Scottsdale, Arizona: Get higher returns with Airbnb Rentals
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Your team of (Airbnb) Short-Term Rental Experts in 
          Phoenix and Scottsdale. Find A Property Manager, Real Estate Agents, Airbnb Cleaners, 
          Airbnb Consulting, 1031 Exhange"
        />
        <meta
          property="og:title"
          content="Phoenix-Scottsdales, Arizona: Get higher returns with Airbnb Rentals"
        />
        <meta
          property="og:description"
          content="Your team of (Airbnb) Short-Term Rental Experts in Phoenix-Scottsdales. 
          Find A Property Manager, Real Estate Agents, Airbnb Cleaners, Airbnb 
          Consulting, 1031 Exhange"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/phoenix-scottsdale-az"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Header size={1} font={Font.Pacifico}>
        Create Wealth With Airbnb Rentals
      </Header>
      <Section style={{ marginBottom: "3rem" }}>
        <Header size={2}>Phoenix/Scottsdale, Arizona</Header>
        <SplitView split="7:3">
          <div style={{ gridRowStart: mobileSize ? "2" : "inherit" }}>
            <Text block align="left">
              We are your team of short-term rental experts, one call away, who
              help you navigate the home buying journey in Phoenix-Scottsdales,
              Arizona.
            </Text>
            <Text block align="left">
              We are there for our investors from idea inception to post close
              management of an Airbnb rental.
            </Text>
            <Text
              align="left"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "var(--quinary)"
              }}
              onClick={handleInvestorGuideClick}
            >
              Dont forget to read our Investor Guide To Airbnb Rental In
              Phoenix-Scottsdales, AZ
            </Text>
            <Button
              block
              color={Color.tertiary}
              // style={{ [mobileSize ? "marginTop" : "marginLeft"]: "1rem" }}
              style={{ margin: "2rem auto" }}
              onClick={handleContactUsClick}
              data-id="chalet-concierge_get_started_button"
            >
              Get Started
            </Button>
          </div>
          <Image
            src="./images/invest.webp"
            alt="Invest in Airbnb Rentals With Chalet"
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "250px" : "100%"
            }}
            $mobileSize={mobileSize}
          />
        </SplitView>
        <SectionFooter $mobileSize={mobileSize} />
      </Section>
      <Group style={{ marginBottom: "3rem" }}>
        <Section>
          <Header size={2}>
            Work With a Realtor That Specializes in Airbnb Rentals
          </Header>
          <SplitView split="2:3">
            <Image
              src="./images/partner-realtor-musculine.webp"
              alt="Find A Realtor that specializes in Airbnb Rentals in Phoenix-Scottsdales"
              style={{ padding: "1rem" }}
              $mobileSize={mobileSize}
            />
            <div>
              <Text block>
                You can easily find a realtor anywhere in the US but can you
                easily find a real estate agent who understands short-term and
                vacation rentals in Phoenix-Scottsdales, Arizona?
              </Text>
              <Text block>
                It&apos;s a challenge finding the right partner in a new
                investment market. So let Chalet do work for you at no charge.
                We tediously vet our partner realtors in Phoenix-Scottsdales,
                Arizona so you dont have to.
              </Text>
              <Button
                block
                color={Color.tertiary}
                onClick={handleFindARealtorClick}
                style={{ margin: "2rem auto" }}
                data-id="chalet-locations-fort-lauderdale_find-realtor_button"
              >
                FIND A REALTOR
              </Button>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group style={{ marginBottom: "3rem" }}>
        <Section>
          <Header size={2}>Streamline Your Property Management Search</Header>
          <SplitView split="7:3">
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block>
                Choosing the right property management partner is as important
                as picking the perfect property for your Short-Term (Airbnb)
                Rental. But the search can be cumbersome in Phoenix-Scottsdales,
                Arizona.
              </Text>
              <Text block size="1.5rem" weight={400}>
                So let Chalet do the work for you at no charge.
              </Text>
              <Text block>
                We&apos;ve done the research and vetted the candidates, so you
                don&apos;t have to. You&apos;ll have full access to our Chalet
                Partner Property Management Network ensuring peace of mind,
                knowing your property is in safe and competent hands.
              </Text>
            </div>
            <Image
              src="./images/manage.webp"
              alt="Rental"
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",
                padding: "1rem",
                borderRadius: "0.25rem",
                maxWidth: mobileSize ? "100%" : "400px",
                width: "100"
              }}
              $mobileSize={mobileSize}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.tertiary}
              onClick={handleFindPropertyManagerClick}
              data-id="chalet-locations-fort-lauderdale_find-property-manager_button"
            >
              Find A Property Manager
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group style={{ marginBottom: "3rem" }}>
        <Section>
          <Header size={2}>
            Top Rated Airbnb Cleaners on Your Management Team
          </Header>
          <SplitView split="2:3">
            <Image
              src="./images/sell.webp"
              alt="Find Airbnb Cleaners in Phoenix-Scottsdales"
              $mobileSize={mobileSize}
              // style={{ padding: "1rem", borderRadius: "0.25rem" }}
            />
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block>
                When self-managing an Airbnb, it&apos;s essential to work with
                an expert cleaning crew that understands the unique requirements
                for short-term rental properties. A typical cleaning crew does
                not consider Airbnb essentials like restocking inventory or
                changing and cleaning dirty linens.
              </Text>
              <Text block size="1.5rem" weight={400}>
                Let Chalet find the right cleaning partner for you at no charge.
              </Text>
              <Text block size="1.3rem">
                Chalet vetted cleaners in Phoenix-Scottsdales, Arizona have the
                know how to not only provide a spotless clean but also turnover
                the home specifically with Airbnb guests in mind.
              </Text>
              <Button
                block
                color={Color.tertiary}
                style={{
                  margin: "2rem auto"
                }}
                onClick={handleFindAirbnbCleanersClick}
                data-id="chalet-locations-fort-lauderdale_find-airbnb-cleaners_button"
              >
                Find Airbnb Cleaners
              </Button>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group style={{ marginBottom: "3rem" }}>
        <Section>
          <Header size={2}>We Help You Build Thriving Airbnb Businesses</Header>
          <SplitView split="7:3">
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block size="1.3rem">
                So you&apos;ve bought a Airbnb investment property, now what?
                There is no single answer to this questions as there are various
                approaches and strategies to managing an Airbnb business. But
                you don&apos;t have to go at it alone - Chalet is here to help
                you launch your Airbnb business in Phoenix-Scottsdales, Arizona.
                We provide custom solutions based on the individual needs of
                each client. Some of the services we provide include:
              </Text>
              <ul>
                <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                  Airbnb & VRBO Listings Setup
                </li>
                <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                  Setup and training with Property Managemet System
                </li>
                <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                  Smart Dynamic Pricing Automations
                </li>
                <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                  Build your own team with Chalet network of cleaners and
                  maintenace professionals
                </li>
              </ul>
              <Text
                block
                size="1.3rem"
                align="left"
                weight={300}
                style={{ lineHeight: "2rem" }}
              >
                Looking for a custom service not listed above? Let&apos;s
                connect to see if Chalet can help!
              </Text>
            </div>
            <Image
              src="./images/chalet-banner.webp"
              alt="Need help launching your Airbnb Business?"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.tertiary}
              onClick={handleAirbnbConsultingClick}
              data-id="chalet-locations-fort-lauderdale_airbnb-consulting_button"
            >
              Get Started
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group>
        <Section>
          <Header size={2}>
            Get Higher Yields Through Airbnb Rentals With 1031 Exchange
          </Header>
          <SplitView split="2:3">
            <Image
              src="./images/1031exchange.webp"
              alt="Need help launching your Airbnb Business?"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",

                borderRadius: "0.25rem"
              }}
            />
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block>
                A 1031 exchange allows investors to defer all capital gain taxes
                when selling a property if they reinvest the proceeds in a new
                property or portfolio of properties, including Airbnb Rentals in
                Fort Lauderdale, Florida.
              </Text>
              <Text block>
                Work with Chalet, your team of short-term rental experts and
                Airbnb specialists to help you find your next 1031 exchange
                property.
              </Text>
              <Text block>
                New to 1031 exchanges? No problem, we are here to connect you
                with the right resources to decipher if this investment strategy
                works for you.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.tertiary}
              onClick={handle1031ExchangeClick}
              data-id="chalet-locations-fort-lauderdale_1031-excange_button"
            >
              Get Started
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <ContactUsModal />
    </div>
  );
};

export default ChaletConcierge;
