import styled from "styled-components";
import { Color } from "../types";

const HR = styled.hr<{ $color?: Color }>`
  // border-color: var(--${(props) => props.$color});
  background-color: var(--${(props) => props.$color});
  border: 0;
  height: 1px;
  margin: 2.5rem 10rem;
`;

HR.defaultProps = {
  $color: Color.quinary
};

export default HR;
