import { CognitoUser } from "@aws-amplify/auth";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";

export interface AppContextProps {
  openContactModal: boolean;
  openLoginModal: boolean;
  isMobileMenuOpen: boolean;
  isCostSegModalOpen: boolean;
  mobileSize: boolean;
  realtorPages: boolean;
  openCookiesConsentModal: boolean;
  calcLat: any;
  calcLng: any;
  address: string; // Add this line
  setOpenContactModal: Dispatch<SetStateAction<boolean>>;
  setOpenLoginModal: Dispatch<SetStateAction<boolean>>;
  setOpenCostSegModal: Dispatch<SetStateAction<boolean>>;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  setRealtorPages: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<any>>;
  setCalcLat: Dispatch<SetStateAction<any>>;
  setCalcLng: Dispatch<SetStateAction<any>>;
  setOpenCookieConsentModal: Dispatch<SetStateAction<boolean>>;
  setAddress: Dispatch<SetStateAction<string>>; // Add this line
  setPrerenderHeight: Dispatch<SetStateAction<any>>;
  user: CognitoUser | undefined;
  prerenderHeight: string;
}

export const AppContext = createContext<AppContextProps>({
  openContactModal: false,
  openLoginModal: false,
  isMobileMenuOpen: false,
  isCostSegModalOpen: false,
  mobileSize: false,
  realtorPages: false,
  calcLat: undefined,
  calcLng: undefined,
  openCookiesConsentModal: false,
  address: "", // Add this line
  setOpenContactModal: () => undefined,
  setOpenLoginModal: () => undefined,
  setOpenCostSegModal: () => undefined,
  setIsMobileMenuOpen: () => undefined,
  setRealtorPages: () => undefined,
  setUser: () => undefined,
  setOpenCookieConsentModal: () => undefined,
  setCalcLat: () => undefined,
  setCalcLng: () => undefined,
  setAddress: () => undefined, // Add this line
  setPrerenderHeight: () => "0px",
  user: undefined,
  prerenderHeight: "0px"
});

const AppContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<CognitoUser | undefined>();
  const [mobileSize, setMobileSize] = useState(window?.innerWidth < 720);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCostSegModalOpen, setOpenCostSegModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [realtorPages, setRealtorPages] = useState(false);
  const [openCookiesConsentModal, setOpenCookieConsentModal] = useState(false);
  const [calcLat, setCalcLat] = useState(0);
  const [calcLng, setCalcLng] = useState(9);
  const [address, setAddress] = useState(""); // Add this line
  const [prerenderHeight, setPrerenderHeight] = useState("5000px");

  useEffect(() => {
    setMobileSize(window.innerWidth < 720);

    window.addEventListener("resize", () => {
      setMobileSize(window.innerWidth < 720);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        realtorPages,
        openContactModal,
        openLoginModal,
        isMobileMenuOpen,
        isCostSegModalOpen,
        mobileSize,
        openCookiesConsentModal,
        calcLat,
        calcLng,
        prerenderHeight,
        address, // Add this line
        setRealtorPages,
        setOpenContactModal,
        setOpenCookieConsentModal,
        setOpenCostSegModal,
        setCalcLat,
        setCalcLng,
        setIsMobileMenuOpen,
        setOpenLoginModal,
        setUser,
        setAddress, // Add this line
        setPrerenderHeight,
        user
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
